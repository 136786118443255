import '../assets/style/UserDetail.scss'
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined'
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import React, { useEffect, useState } from 'react'
import { CustomerScore } from '../features/userDetail/components/CustomerScore'
import { CustomerDetail } from '../features/userDetail/components/CustomerDetail'
import { MyBooking } from '../features/userDetail/components/MyBooking'
import MyPromotion from '../features/userDetail/components/MyPromotion'
import CustomerCardClassified from '../features/userDetail/components/CustomerCardClassified'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { getData } from '../services/dataProvider'
import { EApi, EServerity, EStatusCode } from '../data/constants'
import { Backdrop, CircularProgress } from '@mui/material'
// import { QRCodeSVG } from 'qrcode.react';
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const UserDetail = () => {
  const [value, setValue] = React.useState(0)
  //snackbar
  const [openMessage, setOpenMessage] = useState(false)
  const [meesageError, setMessageError] = useState('')
  const [serverity, setServerity] = useState('')
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenMessage(false)
  }
  //end snackbar

  // loading
  const [openLoading, setOpenLoading] = useState(false)
  const dataUser =
    localStorage.getItem('userInfo') &&
    JSON.parse(localStorage.getItem('userInfo') || '')
  const token = dataUser?.token
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const fetchData = async () => {
    setOpenLoading(true)
    await getData(EApi.user, { id: dataUser?.id }, (res: any) => {
      if (res.data?.statusCode === EStatusCode.SUCCESS) {
        const dataUser = res.data.data
        const userInfor = JSON.stringify({
          id: dataUser?.id,
          userName: dataUser?.userName,
          email: dataUser?.email,
          phoneNumber: dataUser?.phoneNumber,
          token,
          dateOfBirth: dataUser?.dateOfBirth,
          address: dataUser?.address,
          membershipPoint: dataUser?.membershipPoint,
          nationality: dataUser?.nationality,
          membershipLevel: dataUser?.membershipLevel,
          gender: dataUser?.gender,
        })
        localStorage.removeItem('userInfo')
        localStorage.setItem('userInfo', userInfor)
      } else {
        setMessageError(res.data?.errorMessage)
        if (res.data?.statusCode === EStatusCode.INTERNAL_SERVER_ERROR) {
          setMessageError('Lỗi hệ thống.')
        }
        setServerity(EServerity.ERROR)
        setOpenMessage(true)
      }
    })
    setOpenLoading(false)
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <div className='user-detail'>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
        }}
      >
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={value}
          onChange={handleChange}
          aria-label='Vertical tabs example'
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab
            icon={<ManageAccountsOutlinedIcon />}
            label='Tài khoản'
            {...a11yProps(0)}
          />
          <Tab
            icon={<StarsOutlinedIcon />}
            label='Điểm thưởng của tôi'
            {...a11yProps(1)}
          />
          <Tab
            icon={<AirportShuttleOutlinedIcon />}
            label='Đặt chỗ của tôi'
            {...a11yProps(2)}
          />
          <Tab
            icon={<ReceiptLongOutlinedIcon />}
            label='Thông tin đặc quyền thẻ'
            {...a11yProps(3)}
          />
          <Tab
            icon={<NotificationsActiveOutlinedIcon />}
            label='Khuyến mãi'
            {...a11yProps(4)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <CustomerDetail />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CustomerScore />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MyBooking />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CustomerCardClassified />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <MyPromotion />
        </TabPanel>
      </Box>
      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={serverity as any}
          sx={{ width: '100%' }}
        >
          {meesageError}
        </Alert>
      </Snackbar>
      <Backdrop open={openLoading} sx={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress size={100} color='inherit' />
      </Backdrop>
    </div>
  )
}

export default UserDetail
