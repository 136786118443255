import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import styled from '../assets/style/Booking.module.scss'
import RoomList from '../features/booking/components/RoomList'
import { useNavigate } from 'react-router-dom'
import { EApi, EServerity, EStatusCode, PagePath } from '../data/constants'
import BookingComponent from '../features/home/components/BookingComponent'
import useBookingInfoStore from '../store/bookingInfoStore'
import useBookingRoomsDetailStore from '../store/bookingRoomsDetailStore'
import { getDiffDate, getYYYYMMDD } from '../services/dateUtil'
import React, { useEffect, useState } from 'react'
import { LooseObject, SelectedRoom } from '../data/type'
import { isEmpty } from '../services/stringUtil'
import BookingReview from '../features/booking/components/BookingReview'
import useRoomStore from '../store/roomStore'
import { createData, search } from '../services/dataProvider'
import useBookingStore from '../store/bookingStore'
// snackbar
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Backdrop, CircularProgress } from '@mui/material'
import moment from 'moment'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
const Booking = () => {
  //store
  const {
    startDate = '',
    endDate = '',
    occupancyAdult = 1,
    occupancyChild = 0,
    numOfRoom = 1,
    setBookingInfo = (booking: any) => {},
  } = useBookingInfoStore((state) => state)
  const [totalPrice, setTotalPrice] = useState(0)
  const { setSelectRoomListStore } = useBookingRoomsDetailStore(
    (state) => state
  )
  const { setBooking } = useBookingStore((state) => state)

  const { setListRoom } = useRoomStore((state) => state)
  const { listRoom } = useRoomStore((state) => state)

  const [bookingNight, setBookingNight] = useState(0)
  const [isMember, setIsMember] = useState(true) //TODO
  const [selectedRoomListObject, setSelectedRoomListObject] =
    useState<LooseObject>({})
  const [selectRoomList, setSelectRoomList] = useState<SelectedRoom[]>([])

  const navigate = useNavigate()

  // loading
  const [openLoading, setOpenLoading] = useState(false)

  //snackbar
  const [openMessage, setOpenMessage] = useState(false)
  const [meesageError, setMessageError] = useState('')
  const [serverity, setServerity] = useState('')
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenMessage(false)
  }
  //end snackbar

  useEffect(() => {
    let total = 0
    selectRoomList?.map(
      (item: SelectedRoom) => (total += Number.parseInt(`${item.price}`))
    )
    setTotalPrice(total)
  }, [selectRoomList])

  const doBooking = async () => {
    setOpenLoading(true)
    let total = 0
    selectRoomList?.map(
      (item: SelectedRoom) => (total += Number.parseInt(`${item.price}`))
    )
    const data = {
      reservations: {
        roomRates: selectRoomList?.map((item: any) => ({
          ...item,
          numberOfChild: occupancyChild,
          numberOfAdult: occupancyAdult,
        })),
      },
      arrivalDate: getYYYYMMDD(startDate),
      departureDate: getYYYYMMDD(endDate),
      totalAmount: total,
    }
    setBooking(data)
    selectRoomList.length > 0 &&
      (await createData('pre-order', data, (res) => {
        if (res.data?.statusCode === EStatusCode.SUCCESS) {
          setMessageError('Thao tác thành công.')
          setServerity(EServerity.SUCCESS)
          setOpenMessage(true)
          setSelectRoomListStore(selectRoomList)
          setTimeout(() => {
            navigate(PagePath.Payment)
          }, 1000)
        } else if (res.data?.statusCode === EStatusCode.INTERNAL_SERVER_ERROR) {
          setMessageError('Thao tác thất bại')
          setServerity(EServerity.ERROR)
          setOpenMessage(true)
        } else {
          setMessageError(res?.data?.errorMessage)
          setServerity(EServerity.ERROR)
          setOpenMessage(true)
        }
      }))
    setOpenLoading(false)
  }

  useEffect(() => {
    setBookingNight(getDiffDate(startDate, endDate))
  }, [startDate, endDate])

  const searchRoom = async (data: any) => {
    setOpenLoading(true)
    setListRoom([])
    let roomOccupancy = ''
    for (let index = 0; index < data?.numOfRoom; index++) {
      roomOccupancy += `${data?.occupancyAdult}_${data?.occupancyChild},`
    }
    await search(
      EApi.searchRoom,
      {
        numOfRoom: data?.numOfRoom,
        endDate: getYYYYMMDD(data.endDate),
        startDate: getYYYYMMDD(data.startDate),
        roomOccupancy,
      },
      (response: any) => {
        if (response.data?.statusCode === EStatusCode.SUCCESS) {
          setListRoom(response?.data.data)
          setSelectedRoomListObject({})
          setSelectRoomList([])
          setBookingInfo(data)
          setMessageError('Tìm kiếm thành công.')
          setServerity(EServerity.SUCCESS)
          setOpenMessage(true)
        } else if (
          response.data?.statusCode === EStatusCode.INTERNAL_SERVER_ERROR
        ) {
          setListRoom([])
          setMessageError(response?.data?.statusCode)
          setServerity(EServerity.ERROR)
          setOpenMessage(true)
        } else {
          setListRoom([])
          setMessageError('Không tìm thấy phòng.')
          setServerity(EServerity.ERROR)
          setOpenMessage(true)
        }
      }
    )

    setOpenLoading(false)
  }

  const handleSelectRoom = (room: SelectedRoom) => {
    if (isEmpty(room.bookingName)) return
    selectedRoomListObject[`${room.bookingName}`] = { ...room }
    const selectedList = Object.values(selectedRoomListObject)
    setSelectedRoomListObject(selectedRoomListObject)
    setSelectRoomList(selectedList)
  }

  const createMultiRoomPicker = (num: number) => {
    const roomList = []
    for (let i = 1; i <= num; i++) {
      const room = {
        tabName: `Phòng ${i}`,
        tabKey: `booking${i}`,
        eventKey: `booking${i}`,
      }
      roomList.push(room)
    }
    return roomList
  }

  return (
    <>
      <BookingComponent
        startDate={startDate}
        endDate={endDate}
        numOfRoom={numOfRoom}
        occupancyAdult={occupancyAdult}
        occupancyChild={occupancyChild}
        callOnSubmit={searchRoom}
      />
      {listRoom && listRoom.length > 0 ? (
        <div className={styled.booking}>
          <Row>
            <Col md={8}>
              <Tabs
                defaultActiveKey='booking1'
                id='uncontrolled-tab-example'
                className='mb-3'
              >
                {createMultiRoomPicker(numOfRoom).map((e) => {
                  return (
                    <Tab eventKey={e.eventKey} title={e.tabName} key={e.tabKey}>
                      <div className={styled.roomList}>
                        <div className={styled.room}>
                          <RoomList
                            bookingName={`${e.tabKey}`}
                            selectRoomList={selectRoomList}
                            roomList={listRoom}
                            callSetSelectedRoom={handleSelectRoom}
                          />
                        </div>
                      </div>
                    </Tab>
                  )
                })}
              </Tabs>
            </Col>
            <Col md={4}>
              <BookingReview
                startDate={startDate}
                endDate={endDate}
                bookingNight={bookingNight}
                selectRoomList={selectRoomList}
                occupancyAdult={occupancyAdult}
                occupancyChild={occupancyChild}
                totalPrice={totalPrice}
                onContinue={doBooking}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <></>
      )}
      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={serverity as any}
          sx={{ width: '100%' }}
        >
          {meesageError}
        </Alert>
      </Snackbar>
      <Backdrop open={openLoading} sx={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress size={100} color='inherit' />
      </Backdrop>
    </>
  )
}

export default Booking
