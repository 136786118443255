import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from '../assets/style/Booking.module.scss'
import BaseCheckBox from '../components/form/BaseCheckbox'
import { BaseInput } from '../components/form/BaseInput'
import { BaseTextArea } from '../components/form/BaseTextArea'
import CountrySelect, {
  CountryType,
  countries,
} from '../components/form/CountrySelect'
import { FormProvider } from '../components/form/FormProvider'
import {
  EServerity,
  EStatusCode,
  MESSAGE,
  OPTION_GENDER,
  PagePath,
} from '../data/constants'
import BookingReview from '../features/booking/components/BookingReview'
import { createData } from '../services/dataProvider'
import { getDiffDate } from '../services/dateUtil'
import useBookingInfoStore from '../store/bookingInfoStore'
import useBookingRoomsDetailStore from '../store/bookingRoomsDetailStore'
import useBookingStore from '../store/bookingStore'
import { BaseRadioGroup } from '../components/form/BaseRadioGroup'
import { Backdrop, CircularProgress } from '@mui/material'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Payment = () => {
  //form
  const method = useForm<any>({})
  const { handleSubmit, reset } = method

  const navigate = useNavigate()
  //store
  const { selectRoomList } = useBookingRoomsDetailStore((state) => state)
  const { booking } = useBookingStore((state) => state)
  const {
    startDate = '',
    endDate = '',
    numOfRoom = 1,
    occupancyAdult = 1,
    occupancyChild = 0,
  } = useBookingInfoStore((state) => state)

  //local storage
  const dataUser =
    localStorage.getItem('userInfo') &&
    JSON.parse(localStorage.getItem('userInfo') || '')

  // loading
  const [openLoading, setOpenLoading] = useState(false)

  //snackbar
  const [openMessage, setOpenMessage] = useState(false)
  const [meesageError, setMessageError] = useState('')
  const [serverity, setServerity] = useState('')
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenMessage(false)
  }
  //end snackbar

  //function
  const formatDataUser = (detailData: any) => {
    return {
      ...detailData,
      nationality: countries.find(
        (item: CountryType) => item?.code === detailData?.nationality
      ),
      gender: detailData?.gender ? detailData?.gender : '2',
    }
  }
  useEffect(() => {
    dataUser && reset(formatDataUser(dataUser))
  }, [])
  const onSubmit = async (data?: any) => {
    setOpenLoading(true)
    const body = {
      ...booking,
      profile: {
        ...data,
        nationality: data?.nationality?.code,
        comment: JSON.stringify({
          highFloor: data?.highFloor,
          noSmoking: data?.noSmoking,
          supportDisability: data?.supportDisability,
          flexibleTime: data?.flexibleTime,
          comment: data?.comment,
        }),
        isLogin: !!dataUser?.token,
        userId: dataUser?.id,
      },
    }
    await createData('booking', body, (res) => {
      if (res.data?.statusCode === EStatusCode.SUCCESS) {
        setMessageError('Đặt phòng thành công.')
        setServerity(EServerity.SUCCESS)
        setOpenMessage(true)
        setTimeout(() => {
          navigate(PagePath.Home)
        }, 1000)
      } else if (res.data?.statusCode === EStatusCode.INTERNAL_SERVER_ERROR) {
        setMessageError('Đặt phòng thất bại')
        setServerity(EServerity.ERROR)
        setOpenMessage(true)
      } else {
        setMessageError(res?.data?.errorMessage)
        setServerity(EServerity.ERROR)
        setOpenMessage(true)
      }
    })
    setOpenLoading(false)
  }

  return (
    <div className={styled.booking}>
      <FormProvider method={method} onSubmit={handleSubmit(onSubmit)}>
        <div className={styled.Payment}>
          <Row>
            <Col md={8}>
              <div className={styled.roomList}>Thông tin người đặt chỗ</div>
              <Row>
                <BaseRadioGroup
                  row
                  name='gender'
                  label='Giới tính'
                  options={OPTION_GENDER}
                  defaultValue={'2'}
                />
              </Row>
              <Row>
                <Col>
                  <BaseInput
                    type='text'
                    name='userName'
                    label='Họ Và Tên'
                    sx={{ marginTop: '10px' }}
                    rules={{
                      validate: (value: any) => {
                        if (!value || value === '') return MESSAGE.REQUIRED
                      },
                    }}
                  />
                </Col>
                <Col>
                  <BaseInput
                    type='email'
                    name='email'
                    label='Email nhận thông tin đơn hàng'
                    sx={{ marginTop: '10px' }}
                    rules={{
                      validate: (value: any) => {
                        if (!value) return MESSAGE.REQUIRED
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BaseInput
                    type='number'
                    name='phoneNumber'
                    label='Điện thoại'
                    sx={{ marginTop: '10px' }}
                    rules={{
                      validate: (value: any) => {
                        if (!value) return MESSAGE.REQUIRED
                      },
                    }}
                  />
                </Col>
                <Col>
                  <CountrySelect
                    name='nationality'
                    sx={{ marginTop: '10px ' }}
                    rules={{
                      validate: (value: any) => {
                        if (!value) return MESSAGE.REQUIRED
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row>{/*  */}</Row>

              <div>
                <BaseCheckBox
                  name='highFloor'
                  title='Yêu cầu đặc biệt'
                  label='Tầng cao'
                ></BaseCheckBox>
                <BaseCheckBox
                  name='noSmoking'
                  label='Không hút thuốc'
                ></BaseCheckBox>
                <BaseCheckBox
                  name='supportDisability'
                  label='Hỗ trợ cho người khuyết tật'
                ></BaseCheckBox>
                <BaseCheckBox
                  name='flexibleTime'
                  label='Thời gian trả phòng linh hoạt'
                ></BaseCheckBox>
                <p>
                  Các yêu cầu đặc biệt không đảm bảo sẽ được đáp ứng - tuy
                  nhiên, chúng tôi sẽ cố gắng hết sức để thực hiện.
                </p>
              </div>

              <div>
                <BaseTextArea
                  name='comment'
                  cols={50}
                  minRows={10}
                  placeholder='Yêu cầu khác'
                />
              </div>
            </Col>
            <Col md={4}>
              <BookingReview
                startDate={startDate}
                endDate={endDate}
                bookingNight={getDiffDate(startDate, endDate)}
                selectRoomList={selectRoomList}
                occupancyAdult={occupancyAdult}
                occupancyChild={occupancyChild}
                totalPrice={booking?.totalAmount}
                isPayment
              />
            </Col>
          </Row>
        </div>
        <Snackbar
          open={openMessage}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={serverity as any}
            sx={{ width: '100%' }}
          >
            {meesageError}
          </Alert>
        </Snackbar>
      </FormProvider>
      <Backdrop open={openLoading} sx={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress size={100} color='inherit' />
      </Backdrop>
    </div>
  )
}

export default Payment
