import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { BaseButton } from '../../../components/form/BaseButton'
import { BaseDatePicker } from '../../../components/form/BaseDatePicker'
import { BaseInput } from '../../../components/form/BaseInput'
import { BaseRadioGroup } from '../../../components/form/BaseRadioGroup'
import CountrySelect, {
  CountryType,
  countries,
} from '../../../components/form/CountrySelect'
import { FormProvider } from '../../../components/form/FormProvider'
import {
  EServerity,
  EStatusCode,
  MESSAGE,
  OPTION_GENDER,
} from '../../../data/constants'
import { updateData } from '../../../services/dataProvider'
import { converStringToDate, getYYYYMMDD } from '../../../services/dateUtil'
import { validateEmail } from '../../../utils/ultils'
import { Backdrop, CircularProgress } from '@mui/material'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export const CustomerDetail = (props: any) => {
  const methods = useForm<any>({})
  const { handleSubmit, reset } = methods

  //snackbar
  const [openMessage, setOpenMessage] = useState(false)
  const [meesageError, setMessageError] = useState('')
  const [serverity, setServerity] = useState('')
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenMessage(false)
  }
  //end snackbar

  // loading
  const [openLoading, setOpenLoading] = useState(false)

  const dataUser =
    localStorage.getItem('userInfo') &&
    JSON.parse(localStorage.getItem('userInfo') || '')

  const formatData = (detailData: any) => ({
    ...detailData,
    dateOfBirth: converStringToDate(detailData?.dateOfBirth),
    nationality: countries.find(
      (item: CountryType) => item.code === detailData.nationality
    ),
    gender: detailData?.gender ? detailData?.gender : '2',
  })
  useEffect(() => {
    dataUser && reset(formatData(dataUser))
  }, [])
  const onSubmit = async (data: any) => {
    setOpenLoading(true)

    const token = dataUser?.token
    await updateData(
      'user',
      {
        ...data,
        dateOfBirth: getYYYYMMDD(data?.dateOfBirth),
        nationality: data.nationality?.code,
      },
      (res) => {
        if (res.data?.statusCode === EStatusCode.SUCCESS) {
          const dataUserResponse = res.data.data
          setMessageError('Cập nhật thành công.')
          setServerity(EServerity.SUCCESS)
          setOpenMessage(true)
          const userInfor = JSON.stringify({
            id: dataUserResponse?.id,
            userName: dataUserResponse?.userName,
            email: dataUserResponse?.email,
            phoneNumber: dataUserResponse?.phoneNumber,
            token: token,
            dateOfBirth: dataUserResponse?.dateOfBirth,
            address: dataUserResponse?.address,
            membershipPoint: dataUserResponse?.membershipPoint,
            nationality: dataUserResponse?.nationality,
            membershipLevel: dataUserResponse?.membershipLevel,
            gender: dataUserResponse?.gender,
          })
          localStorage.removeItem('userInfo')
          localStorage.setItem('userInfo', userInfor)
        } else if (res.data?.statusCode === EStatusCode.INTERNAL_SERVER_ERROR) {
          setMessageError('Lỗi hệ thống.')
          setServerity(EServerity.ERROR)
          setOpenMessage(true)
        } else {
          setMessageError(res.data?.errorMessage)
          setServerity(EServerity.ERROR)
          setOpenMessage(true)
        }
      }
    )

    setOpenLoading(false)
  }

  return (
    <>
      <FormProvider method={methods} onSubmit={handleSubmit(onSubmit)}>
        <h5>Thông tin tài khoản</h5>
        <p>{/* <b>Thông tin thẻ</b> */}</p>
        <div>{/* <QRCodeSVG value='user.abc' /> */}</div>
        <div>
          <div>
            <BaseInput
              name='userName'
              required
              label='Tên'
              disabled
              sx={{ marginTop: '20px' }}
            />
          </div>
          <div>
            <BaseRadioGroup
              row
              name='gender'
              required
              label='Giới tính'
              options={OPTION_GENDER}
              defaultValue={'2'}
            />
          </div>
          <Row>
            <Col>
              <BaseInput
                name='email'
                required
                label='Email'
                sx={{ marginTop: '20px' }}
                rules={{
                  validate: (value: any) => {
                    if (!value) return MESSAGE.REQUIRED
                    if (!validateEmail(value)) return MESSAGE.EMAIL
                  },
                }}
              />
            </Col>
            <Col>
              <BaseInput
                type='number'
                name='phoneNumber'
                required
                label='Số điện thoại'
                disabled
                sx={{ marginTop: '20px' }}
                rules={{
                  validate: (value: any) => {
                    if (!value) return MESSAGE.REQUIRED
                  },
                }}
              />
            </Col>
          </Row>
          <BaseInput
            name='address'
            required
            label='Địa chỉ'
            sx={{ marginTop: '20px' }}
            rules={{
              validate: (value: any) => {
                if (!value) return MESSAGE.REQUIRED
              },
            }}
          />
          <Row>
            <Col>
              <BaseDatePicker
                name='dateOfBirth'
                required
                label='Ngày sinh'
                sx={{ marginTop: '20px' }}
                rules={{
                  validate: (value: any) => {
                    if (!moment(value).isValid() || !value)
                      return MESSAGE.REQUIRED
                  },
                }}
              />
            </Col>
            <Col>
              <CountrySelect
                name='nationality'
                sx={{ marginTop: '20px !important' }}
                required
                rules={{
                  validate: (value: any) => {
                    if (!value) return MESSAGE.REQUIRED
                  },
                }}
              />
            </Col>
          </Row>

          <div>
            <BaseButton type='submit'>Lưu</BaseButton>
          </div>
        </div>
      </FormProvider>
      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={serverity as any}
          sx={{ width: '100%' }}
        >
          {meesageError}
        </Alert>
      </Snackbar>
      <Backdrop open={openLoading} sx={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress size={100} color='inherit' />
      </Backdrop>
    </>
  )
}
