import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface RoomStoreProps {
  listRoom: any
  setListRoom: (list: any) => void
}

const useRoomStore = create<RoomStoreProps>()(
  devtools(
    (set) => ({
      listRoom: [],
      setListRoom: (list: any | undefined) =>
        set({
          listRoom: list,
        }),
    }),
    { enabled: true }
  )
)

export default useRoomStore
