import moment from 'moment'

const enum EDateFormat {
  YYYYMMDD = 'YYYY-MM-DD',
  DDMMYYYY_HHmmss = 'DD/MM/YYYY HH:mm:ss',
  DDMMYYYY = 'DD/MM/YYYY',
}

const today = () => moment()

const tomorrow = () => moment(new Date()).add('1', 'day')

const getYYYYMMDD = (date: string) => {
  return moment(date).format(EDateFormat.YYYYMMDD)
}

const getDDMMYYYY_HHmmss = (date: string) => {
  return moment(date).format(EDateFormat.DDMMYYYY_HHmmss)
}
const getDDMMYYYY = (date: string) => {
  return moment(date).format(EDateFormat.DDMMYYYY)
}

const getDiffDate = (date1: string, date2: string) => {
  const mDate1 = moment(date1).format()
  const mDate2 = moment(date2).format()
  return moment(mDate2).diff(moment(mDate1), 'days')
}

const converStringToDate = (date: string) => {
  if (!date) return ''
  if (date === null) return ''
  return moment(date)
}
export {
  today,
  getYYYYMMDD,
  EDateFormat,
  getDiffDate,
  tomorrow,
  converStringToDate,
  getDDMMYYYY_HHmmss,
  getDDMMYYYY,
}
