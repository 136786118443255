import React from 'react';
import '../styles/CustomerCardClassified.scss';
import { BaseButton } from '../../../components/form/BaseButton';
import MemberOffer from './MemberOffer';

function CustomerCardClassified() {
	return (
		<div className='customer-card'>
			<h5>Quyền lợi thành viên</h5>
			<div>
				<div className='mem-card'>
					<img
						src='https://storage.googleapis.com/loyalty-public-arczn36dnada/210920222190_previous_photo_card_6159c9a3-e57f-401c-9e49-cf933d4dcdd5.jpg'
						alt='member'
					/>
				</div>
				<div>
					661292712 AN HỒ Tận hưởng quyền lợi thành thành viên Le Bruder
					Glamping với ưu đãi giảm thêm 5% giá phòng khách sạn tốt nhất cùng
					nhiều ưu đãi hấp dẫn.
				</div>
				<div>
					<h5>Danh mục tìm kiếm</h5>
					<div className='filter'>
						<BaseButton>Tất cả</BaseButton>
						<BaseButton>Lưu trú</BaseButton>
						<BaseButton>Tour khám phá</BaseButton>
					</div>
					<div>
						<MemberOffer />
            <MemberOffer />
            <MemberOffer />
					</div>
				</div>
			</div>
		</div>
	);
}

export default CustomerCardClassified;
