import { Row, Col } from 'react-bootstrap'
import styled from '../assets/style/Tour.module.scss'
import ToursPromo from '../features/tour/components/ToursPromo'
const Tours = () => {
  return (
    <>
      <h4>15 Hoạt Động Du Lịch Sapa Tự Túc Cho Người Thích Khám Phá</h4>
      <p>
        Trước chuyến du lịch Sapa tự túc, bạn đã bao giờ có băn khoăn rằng mình
        sẽ chơi gì, ở đâu hay chưa? Nếu còn lăn tăn mà chưa có câu trả lời chính
        xác, hãy để Le Bruder chia sẻ với bạn các hoạt động du lịch Sapa tự túc
        dành cho những người thích khám phá. Bắt đầu thôi!
      </p>
      <div>
        <h5>1. Chinh Phục Đỉnh Fansipan</h5>
        <div className="image">
          <img
            src="https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_1000/v1596033323/blog/t3rmsv3hu0w8gz3ityzd.webp"
            alt=""
          />
        </div>
        <p>
          Fansipan – nơi được mệnh danh là “nóc nhà Đông Dương” luôn nằm trong
          top các hoạt động du lịch khó có thể bỏ qua khi đến Sapa. Được biết,
          thời điểm lý tưởng nhất để chinh phục đỉnh Fansipan là từ tháng 9 đến
          tháng 4 của năm tiếp theo. Đây là khung thời gian mà thời tiết và khí
          hậu tại Fansipan ổn định nhất, ít mưa và mát mẻ.
        </p>
        <p>
          Mua vé cap treo qua Le Bruder là giải pháp tiện lợi dành cho những ai
          không có ý định sử dụng đường rừng leo núi. Từ lồng cáp treo, bạn có
          thể phóng tầm nhìn ra toàn cảnh ấn tượng của dãy Hoàng Liên Sơn và
          thung lũng Mường Hoa. Bạn cũng đừng bỏ qua Vườn tre, Bảo An thiền tự,
          Bích Vân thiền tự, khu mua sắm v.v... nằm trong khu Fansipan Legend.
        </p>
      </div>
      <div>
        <h5>2. Đi Bộ Đến Bản Cát Cát Sapa</h5>
        <div className="image">
          <img
            src="https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_1000/v1596033324/blog/rhqqnirpz7n8u1phejww.webp"
            alt=""
          />
        </div>
        <p>
          Để tìm hiểu về văn hóa bản làng của người H’Mong đen lâu đời nhất của
          Sapa, bạn nên ghé qua Bản Cát Cát trong chuyến đi của mình. Nơi đây
          chỉ cách trung tâm Nhà thờ đá Sapa khoảng 3km, đường nhựa dễ dàng di
          chuyển, bạn có thể đi bộ để có thể ngắm cảnh, chụp hình thác nước Cát
          Cát hay ruộng bậc thang hùng vĩ.
        </p>
        <p>
          Trong trekking tham quan bản Cát Cát, du khách sẽ được tìm hiểu cuộc
          sống của người H'Mong đen và nét đẹp văn hoá độc đáo của đồng bào miền
          núi. Bạn hãy thử một lần thuê mặc trang phục truyền thống của người
          Sapa để trải nghiệm "chất nàng Mị" nơi buôn làng nhé!
        </p>
      </div>
      <div>
        <h5>3. Trekking Lao Chải - Tả Van</h5>
        <div className="image">
          <img
            src="https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_1000/v1596033316/blog/xj4hezs63xsyoejnkuun.webp"
            alt=""
          />
        </div>
        <p>
          Đi cách xa trung tâm thị trấn Sapa khoảng 10km đến xã Tả Van, bạn sẽ
          đến được Bản Tả Van. Trên đường đi, bạn sẽ đi qua Lao Chải – nơi có
          cảnh thiên nhiên núi rừng đẹp đến “nín thở”, và có cả bãi đá cổ Sapa
          nổi tiếng. Khi đi tour trekking làng Lao Chải và Bản Tả Van, du khách
          có cơ hội trải nghiệm sự hiếu khách nồng nhiệt của người dân và nếm
          thử các món ngon địa phương.
        </p>
        <p>
          Khi đến được Tả Van, bạn sẽ cảm nhận được nơi đây hoàn toàn tách biệt
          với sự nhộn nhịp của thị trấn. Những cánh đồng trải dài như bất tận
          không có điểm đầu hay cuối, các căn nhà tranh mộc mạc len lỏi trong
          con đường nhỏ tạo nên sức hút khó cưỡng với “đôi chân” thích xê dịch.
        </p>
      </div>
      <div>
        <h5>4. Thăm Ruộng Bậc Thang Ở Làng Má Tra & Bản Tả Phìn</h5>
        <div className="image">
          <img
            src="https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_1000/v1596033316/blog/zvmdprjozk0how969xme.webp"
            alt=""
          />
        </div>
        <p>
          Làng Má Tra và Bản Tả Phìn nằm cách thị trấn Sapa khoảng 12km về hướng
          Đông. Trên đường đi, bạn có thể ngắm nhìn toàn cảnh thiên nhiên hùng
          vĩ và hoang sơ. Đến với làng Má Tra, du khách sẽ được người H’Mông
          chào đón nồng nhiệt và tham quan ruộng bậc thang.
        </p>
        <p>
          Cách đó không xa là bản Tả Phìn, nơi ở của dân tộc người Dao Đỏ. Đến
          Tả Phìn, đừng quên ghé thăm hang động nhũ đá Tả Phìn với các hình thù
          kỳ thú. Còn chần chờ gì mà không chọn ngay siêu tour trekking đến làng
          Má Tra và bản Tả Phìn hấp dẫn cùng Le Bruder?
        </p>
      </div>
      <div>
        <h5>5. Thăm Tu Viện Cổ Tả Phìn</h5>
        <div className="image">
          <img
            src="https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_1000/v1596033289/blog/a0lldjkvqiqrntoujhib.webp"
            alt=""
          />
        </div>
        <p>
          Tu Viện Cổ Tả Phìn là địa điểm khá thú vị khi ghé thăm bản Tả Phìn. Tu
          viện này được xây dựng từ thời Pháp với kiến trúc độc đáo. Tuy bị bỏ
          hoang từ lâu nhưng nó vẫn mang theo vẻ đẹp bí ẩn, ma mị, thu hút được
          nhiều du khách đến với không gian núi đồi hoang sơ. Đây chắc chắn sẽ
          lả phông nền chụp ảnh hoài cổ đặc sắc cho chuyến du lịch Sapa tự túc
          của bạn đấy.
        </p>
      </div>
      <div>
        <h5>5. Thăm Tu Viện Cổ Tả Phìn</h5>
        <div className="image">
          <img
            src="https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_1000/v1596033289/blog/a0lldjkvqiqrntoujhib.webp"
            alt=""
          />
        </div>
        <p>
          Tu Viện Cổ Tả Phìn là địa điểm khá thú vị khi ghé thăm bản Tả Phìn. Tu
          viện này được xây dựng từ thời Pháp với kiến trúc độc đáo. Tuy bị bỏ
          hoang từ lâu nhưng nó vẫn mang theo vẻ đẹp bí ẩn, ma mị, thu hút được
          nhiều du khách đến với không gian núi đồi hoang sơ. Đây chắc chắn sẽ
          lả phông nền chụp ảnh hoài cổ đặc sắc cho chuyến du lịch Sapa tự túc
          của bạn đấy.
        </p>
      </div>
      <div>
        <h5>5. Thăm Tu Viện Cổ Tả Phìn</h5>
        <div className="image">
          <img
            src="https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_1000/v1596033289/blog/a0lldjkvqiqrntoujhib.webp"
            alt=""
          />
        </div>
        <p>
          Tu Viện Cổ Tả Phìn là địa điểm khá thú vị khi ghé thăm bản Tả Phìn. Tu
          viện này được xây dựng từ thời Pháp với kiến trúc độc đáo. Tuy bị bỏ
          hoang từ lâu nhưng nó vẫn mang theo vẻ đẹp bí ẩn, ma mị, thu hút được
          nhiều du khách đến với không gian núi đồi hoang sơ. Đây chắc chắn sẽ
          lả phông nền chụp ảnh hoài cổ đặc sắc cho chuyến du lịch Sapa tự túc
          của bạn đấy.
        </p>
      </div>
      <div>
        <h5>5. Thăm Tu Viện Cổ Tả Phìn</h5>
        <div className="image">
          <img
            src="https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_1000/v1596033289/blog/a0lldjkvqiqrntoujhib.webp"
            alt=""
          />
        </div>
        <p>
          Bản Sín Chải thuộc xã San Sả Hồ, cách thị trấn Sapa tầm 4km và nằm
          cạnh bản Cát Cát. Nếu e ngại bản Cát Cát lúc nào cũng nườm nượp du
          khách thì bạn hãy tìm đến nơi vắng vẻ và mộc mạc hơn của Sín Chải cùng
          những thửa ruộng bậc thang với tuổi đời hàng trăm năm. Thả hồn theo
          khung cảnh núi rừng trùng điệp và đắm chìm vào không gian mây sương
          trong lành là những trải nghiệm tuyệt vời chỉ có ở phố núi.
        </p>
      </div>
      <div>
        <h5>6. Khám Phá Thiên Nhiên Hoang Sơ Ở Bản Sín Chải</h5>
        <div className="image">
          <img
            src="https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_1000/v1596033317/blog/s80gvspjbvgxa0c4koym.webp"
            alt=""
          />
        </div>
        <p>
          Bản Sín Chải thuộc xã San Sả Hồ, cách thị trấn Sapa tầm 4km và nằm
          cạnh bản Cát Cát. Nếu e ngại bản Cát Cát lúc nào cũng nườm nượp du
          khách thì bạn hãy tìm đến nơi vắng vẻ và mộc mạc hơn của Sín Chải cùng
          những thửa ruộng bậc thang với tuổi đời hàng trăm năm. Thả hồn theo
          khung cảnh núi rừng trùng điệp và đắm chìm vào không gian mây sương
          trong lành là những trải nghiệm tuyệt vời chỉ có ở phố núi.
        </p>
      </div>
      <div>
        <h5>7. Check-in Cực "Thơ" Ở Thác Tình Yêu</h5>
        <div className="image">
          <img
            src="https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_1000/v1596033310/blog/xtx2jpotgqdlmevwuotl.webp"
            alt=""
          />
        </div>
        <p>
          Thêm một địa điểm du lịch thần tiên tại Sapa! Thác Tình Yêu nằm cách
          thị trấn Sapa 14km về phía Tây Nam, với độ cao 100m luôn tuôn chảy
          ngày đêm. Nhìn từ xa, thác trông như hình dải lụa mềm mại, nổi bật
          giữa thảm thực vật xanh. Để đến được thác Tình Yêu, khách du lịch cần
          đi bộ băng qua rừng trúc xanh mát rượi. Đặc biệt vào tháng 3, con
          đường sẽ rực rỡ hơn với sắc đỏ của hoa đỗ quyên. Nếu thác Tình Yêu vẫn
          chưa làm bạn thỏa mãn với phong cảnh kỳ thú tại Sapa, hãy tham khảo
          thêm kết hợp của Klook để tận dụng thời gian tham quan.
        </p>
      </div>
    </>
  )
  //   <div className={styled.tour}>
  //     <ToursPromo/>
  //     <ToursPromo/>
  //     <ToursPromo/>
  //     <div>
  //       <h4>3 ngày 2 đêm</h4>
  //       <div>đánh giá:</div>
  //       <div>Giá tour</div>
  //       <div>
  //         <button>Đặt ngay</button>
  //       </div>
  //     </div>
  //     <div>
  //       <h4>2 ngày 1 đêm</h4>
  //       <div>đánh giá:</div>
  //       <div>Giá tour</div>
  //       <div>
  //         <button>Đặt ngay</button>
  //       </div>
  //     </div>
  //     <div>
  //       <h4>Đăng ký lưu trú dài hạn</h4>
  //       <Row>
  //         <Col>
  //           <div>
  //             <h4>Những lối mòn ẩn trong lòng Sapa</h4>
  //             <div>Short description</div>
  //             <div>
  //               <button>Xem thêm</button>
  //             </div>
  //           </div>
  //         </Col>
  //         <Col>
  //           <img src="" alt="" />
  //         </Col>
  //       </Row>
  //       <Row>
  //         <Col>
  //           <div>
  //             <h4>Khám phá Nậm Pạ & thưởng thức BBQ</h4>
  //             <div>Short description</div>
  //             <div>
  //               <button>Xem thêm</button>
  //             </div>
  //           </div>
  //         </Col>
  //         <Col>
  //           <img src="" alt="" />
  //         </Col>
  //       </Row>
  //     </div>
  //     <div>
  //       Tour xe máy liên tỉnh
  //       <div>
  //         <img src="" alt="" />
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default Tours
