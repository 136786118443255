import styled from '../styles/PromotionCard.module.scss';
import { useTranslation } from 'react-i18next';
import ButtonLink from '../../../components/ui/ButtonLink';
const PromotionCard = ({
	imgUrl,
	title,
	shortDescription,
	expDt,
	link
}: {
	imgUrl: string;
	title: string;
	shortDescription?: string;
	expDt: string;
	link: string;
}) => {
	const { t } = useTranslation();
	return (
		<div className={styled.promotionCard}>
			<div className={styled.image}>
				<img src={imgUrl} alt={title} />
			</div>
			<div className={styled.content}>
				<div className={styled.title}>
					<h5>{title}</h5>
				</div>
				{shortDescription && (
					<div>
						<article>Short description</article>
					</div>
				)}
				{expDt?.length > 0 && <div>{expDt}</div>}
				<div>
					<ButtonLink
						text={t('content.common.detail')}
						href={link}
					/>
				</div>
				<div>
					<ButtonLink
						text={t('content.common.bookNow')}
						href={link}
					/>
				</div>
			</div>
		</div>
	);
};
export default PromotionCard;
