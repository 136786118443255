import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface AppState {
    currentPage?: string | undefined
    setCurrentPage: ( page: string ) => void
}

const useAppStore = create<AppState>()(
    devtools(
        persist(
            ( set ) => ( {
                currentPage: '',
                setCurrentPage: ( page ) => set( () => ( { currentPage: page } ) ),
            } ),
            {
                name: 'app-storage',
            }
        )
    )
)

export default useAppStore;