function isEmpty(str: any) {
  return !str || str.length === 0
}

const currencyFormat = (value: string | number, locale: string) => {
  // console.log("format: ", value)
  return Number(value).toLocaleString(locale)
}

const getCurrencySign = () => {
  return '₹'
}
export { isEmpty, currencyFormat, getCurrencySign }
