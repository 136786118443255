import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownItem from 'react-bootstrap/DropdownItem'
import PropTypes from 'prop-types'
import { useState } from 'react'
import i18n from '../../services/i18n'
import '../../assets/style/LanguagePicker.scss'
const LanguagePicker = () => {
    const [langId, setLang] = useState('vi')

    const languages = [
        { id: 'en', label: 'English' },
        { id: 'vi', label: 'Tiếng Việt' },
    ]
    const getLabel = (id: string) => {
        return languages.find((item) => item.id === id)?.label
    }
    const selectLanguage = (e: any): any => {
        const id = e.target.id
        const selectedLanguage = languages.find((item) => item.id === id)?.label
        i18n.changeLanguage(id)
        setLang(id)
    }
    return (
        <div className='language-picker'>
            <LanguageOutlinedIcon />
            <Dropdown>
                <Dropdown.Toggle variant='success' id='dropdown-basic'>
                    {getLabel(langId)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <DropdownItem
                        onClick={selectLanguage}
                        id='vi'
                        className='item'
                    >
                        Tiếng Việt
                    </DropdownItem>
                    <DropdownItem
                        onClick={selectLanguage}
                        id='en'
                        className='item'
                    >
                        English
                    </DropdownItem>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
export default LanguagePicker

LanguagePicker.propTypes = {
    name: PropTypes.string,
}
