import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { SelectedRoom } from '../data/type'

interface BookingRoomsDetailStore {
  selectRoomList?: SelectedRoom[]
  setSelectRoomListStore: (roomList: SelectedRoom[]) => void
}

const useBookingRoomsDetailStore = create(
  devtools<BookingRoomsDetailStore>(
    (set) => ({
      setSelectRoomListStore: (roomList: SelectedRoom[] | undefined) =>
        set({
          selectRoomList: roomList ? roomList : [],
        }),
    }),
    { enabled: true }
  )
)

export default useBookingRoomsDetailStore
