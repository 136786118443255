const SpecialOffers = () => {
  return (
    <>
      <div>
        <h4>Tổng hợp các ưu đãi đang diễn ra</h4>
      </div>
      <div>
        <h5>1. Tặng bạn mới voucher ẩm thực 50K</h5>
        <>
          <div className="image">
            <img
              src="https://i.ibb.co/NCwzww6/Screen-Shot-2023-07-09-at-21-46-54.png"
              alt="50"
            />
          </div>
          <p>
            Tặng voucher ẩm thực trị giá 50K cho khách hàng mới checkin tại khu
            nghỉ dưỡng. Mỗi Voucher chỉ áp dụng với 01 hoá đơn giá trị từ
            100.000VND trở lên. Voucher ẩm thực không áp dụng tại gian hàng Phố
            mua sắm. Cách sử dụng: Khách hàng đăng ký thành viên Le Bruder
            Glamping lần đầu, đăng nhập, truy cập mục Ưu đãi của tôi và xuất
            trình mã QR voucher ẩm thực trị giá 50K cho nhân viên tại quầy thanh
            toán.
            <br />
            Thời gian áp dụng: 1/7 – 31/7/2023
            <br />
            Thời hạn sử dụng voucher: 1/7 – 31/7/2023
          </p>
        </>
      </div>
      <div>
        <h5>2. Gội đầu dưỡng sinh & giảm 20% dịch vụ spa</h5>
        <>
          <div className="image">
            <img src="https://i.ibb.co/ZgH7QW4/khuyenmaispa.png" alt="50" />
          </div>
          <p>
            Khác với hình thức gội đầu thông thường hằng ngày, gội đầu dưỡng
            sinh là phương pháp phổ biến ở các spa và hair salon, tập trung vào
            việc chăm sóc tóc và da đầu, bao gồm làm sạch bằng dầu gội, nước gội
            thảo dược từ thiên nhiên như bồ kết, sả, vỏ bưởi…, kết hợp với
            massage đầu – cổ – vai – gáy – lưng, day ấn huyệt… nhằm đem lại cảm
            giác thư giãn và sảng khoái.
            <br />
            Le Bruder tặng bạn 20% để trải nghiệm các dịch vụ spa mà không cần
            sử dụng dịch vụ phòng.
            <br />
            Thời gian áp dụng: 1/7 – 31/7/2023
            <br />
            Thời hạn sử dụng voucher: 1/7 – 31/7/2023
          </p>
        </>
      </div>
      <div>
        <h5>
          3. Giảm 50% Combo ẩm thực Tây bắc khi đặt khu phòng Family - Vườn Đào
        </h5>
        <>
          <div className="image">
            <img src="https://i.ibb.co/hBVnS0G/amthuc.jpg" alt="50" />
          </div>
          <p>
            Tặng voucher ẩm thực lên đến 50% cho đặt phòng loại family tại khu
            nghỉ dưỡng. Mỗi Voucher chỉ áp dụng với 01 hoá đơn giá trị từ
            100.000VND trở lên. Voucher ẩm thực không áp dụng tại gian hàng Phố
            mua sắm. Cách sử dụng: Khách hàng đăng khi thanh toán xuất trình mã
            QR voucher ẩm thực trị giá 50% cho nhân viên tại quầy thanh toán.
            <br />
            Thời gian áp dụng: 1/7 – 31/7/2023 Thời hạn sử dụng voucher: 1/7 –
            31/7/2023
          </p>
        </>
      </div>
      <div>
        <h5>5. Miễn phí phương tiện khi đặt tour khám phá</h5>
        <>
          <div className="image">
            <img src="https://i.ibb.co/5ktytD5/tour.jpg" alt="50" />
          </div>
          <p>
            Khi sử dụng dịch vụ lưu trú tại Le Bruder Hồng Thái, quý khách sẽ
            được hướng dẫn/ giới thiệu tour khám phá cuộc sống, phong cảnh địa
            phương một cách tự do. Chúng tôi miễn phí phuơng tiện cho các tour
            trong ngày. Cùng khám phá nhé.
            <br />
            Thời gian áp dụng: 1/7 – 31/7/2023 Thời hạn sử dụng voucher: 1/7 –
            31/7/2023
          </p>
        </>
      </div>
      <div>
        <h5>6. Giảm ngay 20% khi đặt phòng 2 đêm vào ngày trong tuần</h5>
        <>
          <div className="image">
            <img src="https://cucphuongresort.vn/wp-content/uploads/2023/06/Copy-of-CPR_Facebook-Ads-June.png" alt="50" />
          </div>
          <p>
            Giảm ngay 20% khi đặt phòng 2 đêm vào ngày trong tuần nếu bạn đặt
            phòng trong khoảng thời gian từ thứ Hai đến thứ Năm và lưu trú ít
            nhất 2 đêm, bạn sẽ nhận được giảm giá 20% trên tổng số tiền phòng.
            <br />
            Thời gian áp dụng: 1/7 – 31/7/2023 Thời hạn sử dụng voucher: 1/7 –
            31/7/2023
          </p>
        </>
      </div>
    </>
  )
}

export default SpecialOffers
