import { FC } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface OverallMultiItemCarouselProps {
  itemList?: any[];
  responsive?: any;
  autoPlay?: boolean;
  swipeable?: boolean;
  draggable?: boolean;
  deviceType?: string;
  infinite?: boolean;
}
const OverallMultiItemCarousel: FC<OverallMultiItemCarouselProps> = (props) => {
  const {
    deviceType,
    responsive,
    itemList = [],
    autoPlay = true,
    swipeable = true,
    draggable = true,
    infinite = true,
  } = props;
  return (
    <Carousel
      swipeable={swipeable}
      draggable={draggable}
      showDots={false}
      arrows={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={infinite}
      autoPlay={autoPlay}
      autoPlaySpeed={4000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={2000}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      deviceType={deviceType}
      itemClass="carousel-item-padding-40-px"
    >
      {itemList}
    </Carousel>
  );
};

export default OverallMultiItemCarousel;
