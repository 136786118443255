import { PagePath } from "../../../data/constants";
import styled from "../styles/AccommodationCard.module.scss";
const PromotionCard = ({
  imgUrl,
  title,
  id,
}: {
  imgUrl: string;
  title: string;
  shortDescription: string;
  id: string;
}) => {
  return (
    <div className={styled.promotionCard}>
      {/* <a href={`${PagePath.Accommodations}/${id}`}> */}
      <a href={`${PagePath.Accommodations}`}>
        <div className={styled.image}>
          <img src={imgUrl} alt={title} />
        </div>
        <div className={styled.content}>
          <div className={styled.title}>
            <h5>{title}</h5>
          </div>
        </div>
      </a>
    </div>
  );
};
export default PromotionCard;
