import { useNavigate } from "react-router-dom";
import "../assets/style/AccomodationPage.scss";
import OverallMultiItemCarousel from "../components/ui/OverallMultiItemCarousel";
import { PagePath, accommodationPhotoCarouselResponsive } from "../data/constants";
import { bungalowHoaDao, bungalowHoaMan, bungalowVuonLe } from "../data/media";

const Accommodations = () => {
  const navigate = useNavigate()
  const gotoBooking = () => {
    navigate(PagePath.Booking);
  }

  const hoadaoPhotos = bungalowHoaDao.map((e, idx) => {
    return (
      <div className="image" key={idx}>
        <img src={e.url} alt={e.alt} />
      </div>
    );
  });

  const hoamanPhotos = bungalowHoaMan.map((e, idx) => {
    return (
      <div className="image" key={idx}>
        <img src={e.url} alt={e.alt} />
      </div>
    );
  });

  const vuonlePhotos = bungalowVuonLe.map((e, idx) => {
    return (
      <div className="image" key={idx}>
        <img src={e.url} alt={e.alt} />
      </div>
    );
  });
  return (
    <div className="accommodations-page">
      <div className="page-title">
        <h4>BUNGALOW</h4>
        <p>
          12 căn Bungalow mang nhiều hình dáng và phong cách khác nhau đều được
          đầu tư nghiêm túc và chắc chắn nhằm bảo đảm trải nghiệm dịch vụ của
          quý khách. Đồng thời trong mỗi căn Bungalow bạn sẽ tìm thấy những vật
          liệu địa phương dùng để trang trí nội thất nhằm giúp du khách hòa nhập
          vào các nền văn hóa dân tộc anh em. Từ ban công riêng, hãy chiêm
          ngưỡng vẻ đẹp rực rỡ xung quanh của Le Bruder trong khi thưởng thức
          một tách cà phê hay trà buổi sáng với những người thân yêu.
        </p>
      </div>
      <div>
        <div className="image">
          <img src="https://i.ibb.co/VCbxh7x/sodo.jpg" alt="sodo" />
        </div>
      </div>
      <div className="product">
        <h4>Bungalow Hoa Đào</h4>
        <div className="product-intro">
          <article>
            Rộng rãi nhưng ấm cúng, Family Bungalow được xây dựng với khu vực
            sinh hoạt lớn cũng như 02 phòng ngủ riêng biệt kèm 01 gác xếp. Là sự
            lựa chọn hoàn hảo cho các gia đình muốn ở cùng nhau trong một không
            gian
          </article>
          <div className="image">
            <img
              src="https://phoenixmocchauresort.com/wp-content/uploads/2023/06/CVP07962-Large.jpeg"
              alt="family"
            />
          </div>
          <OverallMultiItemCarousel
            itemList={hoadaoPhotos}
            responsive={accommodationPhotoCarouselResponsive}
          />
        </div>
      </div>
      <div className="product">
        <h4>Bungalow Hoa Mận</h4>
        <div className="product-intro">
          <article>
            Rộng rãi nhưng ấm cúng, Family Bungalow được xây dựng với khu vực
            sinh hoạt lớn cũng như 02 phòng ngủ riêng biệt kèm 01 gác xếp. Là sự
            lựa chọn hoàn hảo cho các gia đình muốn ở cùng nhau trong một không
            gian
          </article>
          <div className="image">
            <img
              src="https://i.ibb.co/6XnhSD0/hoaman1.jpg"
              alt="family"
            />
          </div>
          <OverallMultiItemCarousel
            itemList={hoamanPhotos}
            responsive={accommodationPhotoCarouselResponsive}
          />
        </div>
      </div>
      <div className="product">
        <h4>Bungalow Vườn Lê</h4>
        <div className="product-intro">
          <article>
            Rộng rãi nhưng ấm cúng, Family Bungalow được xây dựng với khu vực
            sinh hoạt lớn cũng như 02 phòng ngủ riêng biệt kèm 01 gác xếp. Là sự
            lựa chọn hoàn hảo cho các gia đình muốn ở cùng nhau trong một không
            gian
          </article>
          <div className="image">
            <img
              src="https://i.ibb.co/BKZcQ6m/vuonle1.jpg"
              alt="family"
            />
          </div>
          <OverallMultiItemCarousel
            itemList={vuonlePhotos}
            responsive={accommodationPhotoCarouselResponsive}
          />
        </div>
      </div>
      <div className="booking-now">
        <button onClick={gotoBooking}><h4>Đặt ngay</h4></button>
      </div>
    </div>
  );
};

export default Accommodations;
