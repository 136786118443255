import React from "react";
import Modal from "react-bootstrap/Modal";
import "../assets/css/ImageViewer.scss";
const ImageViewer = ({
  photoIdx,
  photos,
  show,
  setShow,
}: {
  photoIdx: number;
  photos: Array<any>;
  show: boolean;
  setShow: (isShow: boolean) => void;
}) => {
  const url = photos[photoIdx]?.src;
  return (
    <Modal
      centered
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Body>
        <div className="image">
          <img src={url} alt="photoBook" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageViewer;
