import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
type Booking = {
  ratePlanId?: string
  roomTypeId?: string
  lengthOfStay?: number
  numOfRoom?: number
  startDate?: string
  endDate?: string
  occupancyAdult?: number
  occupancyChild?: number
}
type SearchRoom = {
  startDate?: string
  endDate?: string
  numOfRoom?: number
  occupancyAdult?: number
  occupancyChild?: number
}
interface BookingState {
  booking: any
  roomSearch: SearchRoom
  setBooking: (bookingInfo: any) => void
}

const useBookingStore = create<BookingState>()(
  devtools(
    (set) => ({
      booking: {},
      roomSearch: {},
      setBooking: (bookingInfo: any) =>
        set({
          booking: bookingInfo,
        }),
      setRoomSearch: (data: any) =>
        set({
          roomSearch: {
            startDate: data.startDate,
            endDate: data.endDate,
            numOfRoom: data.numOfRoom,
            occupancyAdult: data.occupancyAdult,
            occupancyChild: data.occupancyChild,
          },
        }),
    }),
    { enabled: true }
  )
)

export default useBookingStore
