import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import '../styles/ShortBookingCard.scss'
import { getDDMMYYYY } from '../../../services/dateUtil'
import { currencyFormat } from '../../../services/stringUtil'

interface ShortBookingCardProps {
  id: string
  bookingDetail: any
  bookingStatus: string
  totalPrice: number | string
}
const ShortBookingCard: FC<ShortBookingCardProps> = (props) => {
  const { id, bookingDetail, bookingStatus, totalPrice } = props
  console.log(bookingDetail)
  return (
    <div className='short-booking-card'>
      <div className='header'>
        <div>Mã đơn hàng: {id}</div>
        <div className='right-item'>
          {bookingStatus === 'RESERVED'
            ? 'Đặt phòng thành công'
            : 'Không thành công'}
        </div>
      </div>
      <div className='content'>
        <Row>
          <Col md={4} sm={6}>
            <div className='image'>
              <img
                src='https://booking-static.vinpearl.com/hotels/d0ab92211ae54c5c9f68f11e4855b39a_VPDSWWPQ1.jpg'
                alt='booking1'
              />
            </div>
          </Col>
          <Col md={8} sm={6}>
            <h5>Le Bruder Glamping</h5>
            <p>
              Thời gian: {getDDMMYYYY(bookingDetail[0]?.checkinDate)} -
              {getDDMMYYYY(bookingDetail[0]?.checkoutDate)}
            </p>
            <p>
              Số lượng: {bookingDetail[0]?.adultQuantity} Người lớn{' '}
              {bookingDetail[0]?.childQuantity > 0
                ? `- ${bookingDetail[0]?.childQuantity}`
                : ''}
            </p>
          </Col>
        </Row>
      </div>
      <div className='footer'>
        <div>{/* <button>Chi tiết</button> */}</div>
        <div className='right-item'>
          <p>Tổng tiền: {currencyFormat(totalPrice, 'vi')}</p>
        </div>
      </div>
    </div>
  )
}

export default ShortBookingCard
