import { useTranslation } from "react-i18next";
import "../styles/Accommodation.scss";
import LBCarousel from "../../../components/ui/LBCarousel";
const Accommodations = () => {
  const { t } = useTranslation();
  return (
    <div className="accommodation">
      <div className="header-image">
        <img
          src="https://static.mservice.io/blogscontents/momo-upload-api-210702102654-637608184142987564.jpeg"
          alt=""
        />
      </div>
      <div className="main-content">
        <h1 className="my-5">Tinh hoa vượt thời gian</h1>
        <p>
          Tự hào đưa Việt Nam lên bản đồ điểm đến sang trọng của thế giới. Đón
          đầu xu hướng phát triển của du lịch nghỉ dưỡng, lựa chọn hợp tác với
          "những người khổng lồ", Le Bruder đã kiến tạo nên những kiệt tác kiến
          trúc độc bản, chất lượng và đẳng cấp quốc tế, được vận hành bởi các
          thương hiệu quản lý khách sạn hàng đầu thế giới. Mỗi công trình ra đời
          và đi vào hoạt động từng bước góp phần làm đẹp những vùng đất, đưa
          Việt Nam trở thành điểm đến được yêu thích của thế giới.
        </p>
      </div>
      <div>
        <LBCarousel/>
      </div>
    </div>
  );
};

export default Accommodations;
