import { useTranslation } from 'react-i18next'
import styled from '../styles/Section.module.scss'
import OverallMultiItemCarousel from '../../../components/ui/OverallMultiItemCarousel'
import { overViewPromotionsCarouselResponsive } from '../../../data/constants'
import AccommodationCard from './AccommodationCard'
import LBCarousel from '../../../components/ui/LBCarousel'
import { useWindowDimensions } from '../../../services/screenUtil'

const HomeAccommodations = () => {
  const { t } = useTranslation()
  const { height, width } = useWindowDimensions()
  let accommodations = [
    <AccommodationCard
      key={1}
      imgUrl='https://phoenixmocchauresort.com/wp-content/uploads/2023/06/CVP07962-Large.jpeg'
      id='2023'
      shortDescription='short description'
      title={`Bungalow Hoa Đào`}
    />,
    <AccommodationCard
      key={2}
      imgUrl='https://i.ibb.co/6XnhSD0/hoaman1.jpg'
      id='2024'
      shortDescription='short description'
      title={`Bungalow Hoa Mận`}
    />,
    <AccommodationCard
      key={3}
      imgUrl='https://i.ibb.co/BKZcQ6m/vuonle1.jpg'
      id='2025'
      shortDescription='short description'
      title={`Bungalow Vườn Lê`}
    />,
  ]
  return (
    <div className={styled.section}>
      <div className={styled.title}>
        <p>{t('content.accommodation.accommodations')}</p>
      </div>
      <div className={styled.largeScreen}>
        <OverallMultiItemCarousel
          responsive={overViewPromotionsCarouselResponsive}
          itemList={accommodations}
          autoPlay={width < 960 ? true : false}
          swipeable={false}
          draggable={false}
          infinite={width < 960}
        />
      </div>
      <div className={styled.smallScreen}>
        <LBCarousel itemList={accommodations} />
      </div>
    </div>
  )
}

export default HomeAccommodations
