import { useTranslation } from "react-i18next";
import styled from "../styles/PostScript.module.scss";
import { Row, Col } from "react-bootstrap";
import ButtonLink from "../../../components/ui/ButtonLink";
const PostScript = () => {
  const { t } = useTranslation();

  return (
    <div className={styled.postScript}>
      <div className={styled.title}>
        <p>{t("content.postScript.postScript")}</p>
      </div>
      <Row>
        <Col sm={12} xs={12} md={6}>
          <div className={styled.image}>
            <img
              src="https://bebooking.vn/uploads/0000/1/2020/10/09/anh-2.jpg"
              alt="postScript"
            />
          </div>
        </Col>
        <Col sm={12} xs={12} md={6}>
          <div className={styled.script}>
            <h4>Gần thiên nhiên để sống an nhiên</h4>
            <article>
              Trong bungalow ấm áp, hãy tắt nguồn điện thoại và giải phóng tâm
              trí khỏi những thông báo liên hồi của mạng xã hội và email công
              việc. Tận hưởng một kỳ nghỉ dưỡng chân thực, trọn vẹn nhất, không
              wifi và tách biệt khỏi cuộc sống hiện đại.
              <ul>
                <li>Đi dạo trong công viên hoặc đến thư viện để đọc sách.</li>
                <li>Tập thể dục hoặc đi bơi tại hồ bơi</li>
                <li>Chơi trò chơi bằng thẻ bài với bạn bè hoặc gia đình.</li>
                <li>Thưởng thức cà phê hoặc trà tại quán cà phê hoặc</li>
                <li>Thử đọc một cuốn sách mới hoặc truyện tranh.</li>
                <li>Tham gia các hoạt động thư giãn như massage, xông hơi, tắm bùn khoáng, tắm nắng.</li>
                <li>Tham gia các hoạt động mua sắm, ăn uống, thưởng thức đặc sản địa phương.</li>
              </ul>
            </article>
            {/* <div>
              <ButtonLink
                text={t("content.common.readMore")}
                href="/postScript"
              />
            </div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default PostScript;
