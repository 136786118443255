import BedRoundedIcon from '@mui/icons-material/BedRounded'
import ChildCareOutlinedIcon from '@mui/icons-material/ChildCareOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from '../styles/BookingComponent.module.scss'
import { useForm } from 'react-hook-form'
import { BaseButton } from '../../../components/form/BaseButton'
import { BaseDatePicker } from '../../../components/form/BaseDatePicker'
import { BaseInput } from '../../../components/form/BaseInput'
import { FormProvider } from '../../../components/form/FormProvider'
import { APP_CONSTANTS, MESSAGE } from '../../../data/constants'
import { today, tomorrow } from '../../../services/dateUtil'
import { FC, useEffect } from 'react'
import moment from 'moment'

interface BookingComponentProps {
  startDate?: string
  endDate?: string
  numOfRoom?: number
  occupancyAdult?: number
  occupancyChild?: number
  callOnSubmit?: (data: any) => void
}
const BookingComponent: FC<BookingComponentProps> = (props) => {
  const { callOnSubmit = (data) => {} } = props
  const { t } = useTranslation()
  const methods = useForm<any>({})
  const { reset, handleSubmit, setValue, getValues, watch } = methods
  useEffect(() => {
    if (props?.startDate) {
      reset(props)
    }
  }, [])
  return (
    <FormProvider method={methods} onSubmit={handleSubmit(callOnSubmit)}>
      <div className={styled.bookingComponent}>
        <Row>
          <Col lg={6} md={6} sm={4}>
            <div className={styled.section1}>
              <div className={styled.innerCol}>
                <div className={styled.label}>Nhận phòng</div>
                <BaseDatePicker
                  className={styled.baseInput}
                  name='startDate'
                  disablePast
                  rules={{
                    validate: (value: any) => {
                      if (!moment(value).isValid() || !value)
                        return MESSAGE.REQUIRED
                    },
                  }}
                  onChange={(value: any) => {
                    setValue('endDate', moment(value).add('1', 'day'), {
                      shouldValidate: true,
                    })
                    setValue('startDate', value, { shouldValidate: true })
                  }}
                  defaultValue={today()}
                />
              </div>
              <div className={styled.innerCol}>
                <div className={styled.label}>Trả phòng</div>
                <BaseDatePicker
                  className={styled.baseInput}
                  name='endDate'
                  rules={{
                    validate: (value: any) => {
                      if (!moment(value).isValid() || !value)
                        return MESSAGE.REQUIRED
                    },
                  }}
                  minDate={
                    (watch('startDate') &&
                      moment(watch('startDate')).add('1', 'day')) ||
                    tomorrow()
                  }
                  defaultValue={tomorrow()}
                />
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={6}>
            <div className={styled.section2}>
              <div className={styled.innerCol}>
                <div>Số phòng</div>
                <BaseInput
                  className={styled.baseInput}
                  showErrorMessage
                  sx={{ marginTop: '10px', width: '110px' }}
                  name='numOfRoom'
                  inputProps={{
                    step: 1,
                    min: APP_CONSTANTS.minRoom,
                    max: APP_CONSTANTS.maxRoom,
                    type: 'number',
                  }}
                  rules={{
                    validate: (value: any) => {
                      if (!value) return MESSAGE.REQUIRED
                      if (
                        value > APP_CONSTANTS.maxRoom ||
                        value < APP_CONSTANTS.minRoom
                      )
                        return MESSAGE.LENGTH_ROOM
                    },
                  }}
                  defaultValue={1}
                  icon={<BedRoundedIcon />}
                />
              </div>
              <div className={styled.innerCol}>
                <div>Người lớn</div>
                <BaseInput
                  showErrorMessage
                  className={styled.baseInput}
                  sx={{ marginTop: '10px', width: '110px' }}
                  name='occupancyAdult'
                  inputProps={{
                    step: 1,
                    min: APP_CONSTANTS.maxAdults,
                    max: APP_CONSTANTS.minAdults,
                    type: 'number',
                  }}
                  rules={{
                    validate: (value: any) => {
                      if (!value) return MESSAGE.REQUIRED
                      if (
                        value > APP_CONSTANTS.maxAdults ||
                        value < APP_CONSTANTS.minAdults
                      )
                        return MESSAGE.LENGTH_ADULT_OCCUPANCY
                    },
                  }}
                  defaultValue={1}
                  icon={<PeopleAltOutlinedIcon />}
                />
              </div>
              <div className={styled.innerCol}>
                <div>Trẻ em</div>
                <BaseInput
                  showErrorMessage
                  className={styled.baseInput}
                  sx={{ marginTop: '10px', width: '110px' }}
                  name='occupancyChild'
                  inputProps={{
                    step: 1,
                    min: APP_CONSTANTS.maxChildren,
                    max: APP_CONSTANTS.minChildren,
                    type: 'number',
                  }}
                  rules={{
                    validate: (value: any) => {
                      if (!value && value !== 0) return MESSAGE.REQUIRED
                      console.log(value)
                      if (
                        value > APP_CONSTANTS.maxChildren ||
                        value < APP_CONSTANTS.minChildren
                      )
                        return MESSAGE.LENGTH_CHILD_OCCUPANCY
                    },
                  }}
                  defaultValue={0}
                  icon={<ChildCareOutlinedIcon />}
                />
              </div>
            </div>
          </Col>
          <Col lg={2} md={2} sm={2}>
            <div className={styled.searchBtn}>
              <BaseButton
                className={styled.baseInput}
                type='submit'
                variant='contained'
                size='large'
              >
                {t('content.common.search')}
              </BaseButton>
            </div>
          </Col>
        </Row>
      </div>
    </FormProvider>
  )
}
export default BookingComponent
