import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

interface CustomTooltipProps extends TooltipProps {
  isError: boolean
}
export const StyledTooltip = styled(
  ({ className, isError, ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ isError }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: isError ? '#efcfd2' : 'rgba(97,97,97,0.9)',
    color: isError ? '#dc3545' : '#fff',
    border: isError ? '#1px solid #ed8690' : 'inherit',
    fontSize: '0.75rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: isError ? '#ed8690' : 'rgba(97,97,97,0.9)',
    fontSize: '0.75rem',
  },
}))
