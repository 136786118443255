import { Services } from './../pages/Services'
export const EAppConstants = {
  companyName: 'Le Bruder',
  googleMapKey: 'AIzaSyB-rgF4YSBKhgfclDhPIV1alwzeQxTGwIE',
  lng: 105.7773269,
  lat: 21.0057787,
  width: 100,
  height: 35,
}

const enum EApi {
  user = 'user',
  userLogin = 'user-login',
  feedback = 'feedback',
  service = 'service',
  searchRoom = 'room-by-condition',
  reservationByUserId = 'booking-by-user-id',
}
const enum Languages {
  en = 'En',
  vi = 'Vi',
}

export const enum APP_CONSTANTS {
  location = 'Fog Oasis',
  maxRoom = 8,
  minRoom = 1,
  maxAdults = 8,
  minAdults = 1,
  maxChildren = 8,
  minChildren = 0,
}

export const enum MessageType {
  ERROR,
  SUCCESS,
}

const responsive = {
  midDesktop: {
    breakpoint: { max: 2047, min: 1280 },
    items: 3,
  },
  minDesktop: {
    breakpoint: { max: 1279, min: 1069 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1068, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const overViewMultiItemCarouselResponsive = {
  midDesktop: {
    breakpoint: { max: 2047, min: 1280 },
    items: 1,
  },
  minDesktop: {
    breakpoint: { max: 1279, min: 960 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 959, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const overViewPromotionsCarouselResponsive = {
  midDesktop: {
    breakpoint: { max: 2047, min: 1280 },
    items: 3,
  },
  minDesktop: {
    breakpoint: { max: 1279, min: 960 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 959, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const accommodationPhotoCarouselResponsive = {
  midDesktop: {
    breakpoint: { max: 2047, min: 1280 },
    items: 3,
  },
  minDesktop: {
    breakpoint: { max: 1279, min: 960 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 959, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const enum PagePath {
  Payment = '/payment',
  Home = '/',
  ContactUs = '/contactUs',
  SpecialOffers = '/specialOffers',
  Tours = '/tours',
  StoriesPage = '/ourStories',
  Accommodations = '/accommodations',
  Accommodation = '/accommodation/:id',
  MainPostScript = '/postScript',
  Booking = '/booking',
  Login = '/login',
  UserDetail = '/user/:id',
  Feedback = '/feedback',
  Services = '/services',
  Promotion = '/promotion/:id',
  Logout = '/logout',
  ActingService = '/actingService',
  MyBooking = '/myBooking',
  BookingHistory = '/bookingHistory',
}

const enum Color {
  green = '#198754',
  purpil = 'rgb(93, 0, 242)',
}

const enum EStatusCode {
  SUCCESS = 'SUCCESS',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

const enum EServerity {
  SUCCESS = 'success',
  ERROR = 'error',
}

export {
  EServerity,
  EStatusCode,
  PagePath,
  Languages,
  // responsive
  responsive,
  overViewMultiItemCarouselResponsive,
  overViewPromotionsCarouselResponsive,
  accommodationPhotoCarouselResponsive,
  // API
  EApi,
  OPTION_GENDER,
}

export const OPTION_FEEDBACK_5 = [
  {
    value: 'verySatisfied',
    label: 'Rất hài lòng',
  },
  {
    value: 'satisfied',
    label: 'Hài lòng',
  },
  {
    value: 'neutral',
    label: 'Trung bình',
  },
  {
    value: 'dissatisfied',
    label: 'Không hài lòng',
  },
  {
    value: 'veryDissatisfied',
    label: 'Rất không hài lòng',
  },
]
export const OPTION_FEEDBACK_5_1 = [
  {
    value: 'veryLikely',
    label: 'Rất có khả năng',
  },
  {
    value: 'likely',
    label: 'Có khả năng',
  },
  {
    value: 'neutral',
    label: 'Trung lập',
  },
  {
    value: 'unlikely',
    label: 'Không chắc',
  },
  {
    value: 'veryUnlikely',
    label: 'Rất khó xảy ra',
  },
]
const OPTION_GENDER = [
  {
    value: '0',
    label: 'Ông',
  },
  {
    value: '1',
    label: 'Bà',
  },
  {
    value: '2',
    label: `Khác`,
  },
]
export const OPTION_FEEDBACK_4 = [
  {
    value: 'excellent',
    label: 'Xuất sắc',
  },
  {
    value: 'good',
    label: 'Tốt',
  },
  {
    value: 'average',
    label: 'Trung bình',
  },
  {
    value: 'notGood',
    label: 'Không được tốt',
  },
]
export const OPTION_FEEDBACK_3 = [
  {
    value: 'yes',
    label: 'Có',
  },
  {
    value: 'no',
    label: 'Không',
  },
  {
    value: 'notApplicable',
    label: 'Không áp dụng',
  },
]

export const MESSAGE = {
  REQUIRED: 'Trường này bắt buộc nhập.',
  EMAIL: 'Định dạng email không hợp lệ.',
  FORMAT_PHONE: 'Định dạng số không hợp lệ.',
  LENGHT_FORMAT_PHONE: 'Số điện thoại phải có ít nhất 9 ký tự.',
  CONFIRM_PASSWORD: 'Mật khẩu nhập lại không trùng khớp.',
  LENGTH_ADULT_OCCUPANCY: 'Số người lớn phải nhỏ hơn 8 và lớn hơn 0.',
  LENGTH_CHILD_OCCUPANCY: 'Số trẻ phải nhỏ hơn 8 và lớn hơn hoặc bằng 0.',
  LENGTH_ROOM: 'Số phòng phải nhỏ hơn 8 và lớn hơn 0.',
}

export const LIST_AMENITY_RESORT = [
  {
    id: '4665f552-f47e-48b9-8f8e-f0ef9d8b425e',
    name: 'Lễ tân 24/7',
    code: '24HFO',
    description: '24 hours Front desk services',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/87c65c67fd1d464c93c242325f4cd897_Front Desk.png',
    order: 0,
  },
  {
    id: '2812fb2c-9ca1-4788-ad15-b7f5bf26fdea',
    name: 'Bể bơi',
    code: 'POOL',
    description: 'Swimming pool',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/2f806689dff346d2819a66d5bc3904cb_Be boi.png',
    order: 0,
  },
  {
    id: '85bdb83b-5585-4920-bec4-398a0a2c954f',
    name: 'Nhà hàng',
    code: 'RESTAURANT',
    description: 'Restaurant',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/15aefde33c7a4f0ba6a38b980a8e5208_Nha hang.png',
    order: 0,
  },
  {
    id: '903a0ed8-c398-4ae0-a02d-ffbbb24b6594',
    name: 'Spa',
    code: 'SPA',
    description: 'Spa',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/50c44ac81c6e4592910033db656cdeec_Massage.png',
    order: 0,
  },
  {
    id: 'ad054fc3-e4f6-463b-b3e6-f3a4d01cde5e',
    name: 'Hồ bơi ngoài trời',
    code: 'OP',
    description: 'Outdoor Swimming Pool',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/f7a3d124f07945eca1c7387d459fa7d7_Be boi.png',
    order: 0,
  },
  {
    id: 'f4ca70b5-edbb-403f-bd32-600e5b54cdee',
    name: 'Bãi biển riêng',
    code: 'BEACH',
    description: 'Private beach',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/f4ca50ac19f0412aabc74cd4722270bd_Beach.png',
    order: 0,
  },
  {
    id: '0726b904-2d24-4028-af9c-294a074a79f2',
    name: 'Phòng Gym',
    code: 'GYM',
    description: 'Gym',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/b33bc0167d174e8c82df9325f0adba16_Gym.png',
    order: 0,
  },
  {
    id: '08d8a945-f43a-ddbe-a01f-520af461df35',
    name: 'Bãi đỗ xe',
    code: 'PARKING',
    description: 'Parking',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/ca7359ba656e47b6954563d721eff873_Do xe.png',
    order: 0,
  },
  {
    id: '08d8abe0-6560-db19-564c-ec0ecbd4226c',
    name: 'Tiệm làm đẹp',
    code: 'SALON',
    description: 'Beauty salon',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/debb926401b64384bb3fd72ac28a0246_Beauty Salon.png',
    order: 0,
  },
  {
    id: '08d8abe0-82c0-737f-d7d5-6c8042d8edc8',
    name: 'Sục, xông hơi',
    code: 'SAUNA',
    description: 'Sauna & Jacuzzi ',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/704533123f204346b76fd97f4228aa39_Suc xong hoi.png',
    order: 0,
  },
  {
    id: '08d8abe0-ec6a-ad07-e400-63e122cc5fed',
    name: 'Quầy lưu niệm ',
    code: 'SOUV',
    description: 'Souvenir Shop',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/a3a81f226e1f419a8ecda58bb3a331b6_Quay luu niem.png',
    order: 0,
  },
  {
    id: 'd0e31c98-07cf-484f-8a8f-e10da685cdb5',
    name: 'Sân Golf',
    code: 'GOLF',
    description: 'Golf course',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/35a9aefe83014ccaa8ced960435d6420_Golf Course.png',
    order: 0,
  },
  {
    id: 'ecf55250-7aac-4cfe-ba92-30b6a3bbfb51',
    name: 'Câu lạc bộ Trẻ em',
    code: 'KIDCLUB',
    description: 'Kids Club',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/d171cf19d1c14e7792f7954f4c7f7a00_Khu vui choi tre em.png',
    order: 0,
  },
  {
    id: '08d8abe1-dbe5-0b6c-0f2b-640d4c6f259f',
    name: 'Dịch vụ trông trẻ',
    code: 'BABYSITTER',
    description: 'Babysitter',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/14a64ee701f2418f8d7bb73f8f1dd0bf_Trong tre.png',
    order: 0,
  },
  {
    id: '4dedb77f-7b5d-4ce7-a343-1be28546ae23',
    name: 'Giặt ủi',
    code: 'LAV',
    description: 'Laundry service',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/48ce996a8f084aa28f074a77a77cb71f_Dong ho.png',
    order: 0,
  },
]

export const LIST_AMENITY_ROOM = [
  {
    id: 'ea5dd94c-2295-423d-b6e6-ddd5991cff28',
    name: 'Cân sức khỏe',
    code: 'SCALES',
    description: 'Electronic scales',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/1832825c309d44ef839308ca0a490aa2_Can.png',
    order: 0,
  },
  {
    id: '0844cb6f-e1e7-4a2b-9409-452ebe07ba1a',
    name: 'Điện thoại',
    code: 'TEL',
    description: 'Telephone',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/c2fe380b61ce4e7ebd9b684400370a75_Dien thoai.png',
    order: 0,
  },
  {
    id: '3f30328a-226f-4cb3-87cb-969c030f43ac',
    name: 'Minibar',
    code: 'MINIBAR',
    description: 'Minibar',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/1f5ed559756d427a9c349a55f0e6084c_Minibar.png',
    order: 0,
  },
  {
    id: 'aaf63cdc-93ed-4188-a575-1149ed9639bd',
    name: 'Vòi sen ',
    code: 'Shower',
    description: 'Shower',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/8edbd16313044a0dac493f68592a1fcc_Voi sen.png',
    order: 0,
  },
  {
    id: '0b0c0498-9fcc-47e4-872c-22a0ee0fb938',
    name: 'Điều hoà không khí',
    code: 'AIRCON',
    description: 'Air-conditioner',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/14b03a18593e4a9292786446781841de_Dieu hoa.png',
    order: 0,
  },
  {
    id: '677b4574-c786-4cf7-bf40-0c9ae442989a',
    name: 'Dép đi trong nhà',
    code: 'Slippers',
    description: 'Slippers',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/39a4c4aa1bc94436bd30abd93f13026c_Dep le di phong.png',
    order: 0,
  },
  {
    id: '96a613a5-e2a2-477c-8f40-e61e9d390c9d',
    name: 'Máy sấy tóc',
    code: 'HAIRDRYER',
    description: 'Hairdryer',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/28740c3d7ed7404eba0168a055e989e3_May say toc.png',
    order: 0,
  },
  {
    id: '2d49e50b-37f1-405c-a90b-4c7326fc425f',
    name: 'Mạng tốc độ cao',
    code: 'INTERNET',
    description: 'High-speed internet',
    type: null,
    icon: 'https://res.cloudinary.com/dj5wdbjzu/image/upload/v1688696091/79b710cd-97c6-483e-a0f0-bf77541830ae_High_Speed_Internet_qr7ypz.png',
    order: 0,
  },
  {
    id: 'b83b9e46-e878-420f-b931-b64023376dc0',
    name: 'Áo choàng tắm',
    code: 'Bathrobe',
    description: 'Bathrobe',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/04a785b7d556472393ecb14e9d17e708_Yukata.png',
    order: 0,
  },
  {
    id: '6322ac46-e21e-43bc-b2fc-771dc8e72054',
    name: 'Flat-screen TV',
    code: 'TV',
    description: 'TV',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/534b8219e74240cfb577d7c02e6fbb66_TV.png',
    order: 0,
  },
  {
    id: 'aa1b380c-c0e3-4fbc-ac28-92be95547b5b',
    name: 'Tủ quần áo',
    code: 'WARDROBE',
    description: 'Wardrobe',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/eb62d0eef8c44e958efdd2a1bda1671e_Tu quan ao.png',
    order: 0,
  },
  {
    id: '1527a344-d800-45b7-a380-65537ce00bca',
    name: 'Phòng khách ',
    code: 'LIVING',
    description: 'Living room',
    type: null,
    icon: 'https://res.cloudinary.com/dj5wdbjzu/image/upload/v1688696211/a66f75a4-2f23-472c-85b5-3cc833b23653_Garden_View_s3xwky.png',
    order: 0,
  },
  {
    id: '07a8bbc0-4d5c-4470-a547-0366db960ea8',
    name: 'Két sắt điện tử',
    code: 'SAFE',
    description: 'Electrical safety box',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/c02be0985c5d4dc98538b347b2b90be0_Ket sat.png',
    order: 0,
  },
  {
    id: '08d8604d-f81c-0166-1e98-d941d3dae627',
    name: 'Wifi miễn phí',
    code: 'WIFI',
    description: 'WIFI',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/9fd5a5c793f94cb699487517a4ac07f7_Wifi.png',
    order: 0,
  },
  {
    id: '2812fb2c-9ca1-4788-ad15-b7f5bf26fdea',
    name: 'Bể bơi',
    code: 'POOL',
    description: 'Swimming pool',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/2f806689dff346d2819a66d5bc3904cb_Be boi.png',
    order: 0,
  },
  {
    id: '08d8abde-5e69-edc6-7751-2033f0f897d3',
    name: 'Tủ lạnh ',
    code: 'FRIDGE',
    description: 'Fridge',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/ecfb39871c444d039dfcd5b7c1f7ce79_Tu lanh.png',
    order: 0,
  },
  {
    id: '08d8abde-8610-69bd-6a32-f591a6daf369',
    name: 'Ấm điện',
    code: 'Electric Kettle',
    description: 'Electric Kettle',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/cc8f6457bf8d4b13b32ebe703fc2aee5_Am dun nuoc.png',
    order: 0,
  },
  {
    id: '08d8abde-bc1f-ccb8-0258-127577baf2c5',
    name: 'Trà & Cafe miễn phí',
    code: 'COFFEE',
    description: 'Complimentary tea & coffee set',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/90b6fbd04fed49cbbf66cd13c056b03e_Coffee & Tea.png',
    order: 0,
  },
  {
    id: '08d8abde-f40d-ddd6-9b5e-609eb93114a7',
    name: 'Bồn tắm',
    code: 'BATHTUB',
    description: 'Bathtub',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/7190fa01bb5744979206dd701e198d71_Bon tam.png',
    order: 0,
  },
  {
    id: '08d8abdf-8e9b-913f-a8ec-fe338a0b04d2',
    name: 'Quạt trần ',
    code: 'FAN',
    description: 'Ceiling fans',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/7e5c40c9f9654ba18c98a1013f74af50_Quat tran.png',
    order: 0,
  },
  {
    id: '08d8abdf-b8a0-a4a2-97db-735b8fc72554',
    name: 'Bàn làm việc',
    code: 'DESK',
    description: 'Desk',
    type: null,
    icon: 'https://booking-static.vinpearl.com/hotels/amenity/d79799476a6d4b569f5d06da02896590_Ban.png',
    order: 0,
  },
  {
    id: '08d8abdf-fb2f-5e71-7b49-f1d931fbe706',
    name: 'Khăn tắm',
    code: 'TOWEL',
    description: 'Towel',
    type: null,
    icon: "https://booking-static.vinpearl.com/hotels/amenity/743f38bfa1e34dc1b3ba50aa05b96cd8_Khan tam'.png",
    order: 0,
  },
  {
    id: '235b2e1a-2c50-4de5-941d-b5a6e01aeb57',
    name: 'Giường đơn',
    code: 'TB',
    description: 'Twin-bed',
    type: null,
    icon: 'https://res.cloudinary.com/dj5wdbjzu/image/upload/v1688696091/70506213-1388-42e1-9784-fb369d89d3d7_King_yvowme.png',
    order: 0,
  },
  {
    id: 'be4858ec-76fe-4387-bdee-32665ed831b0',
    name: 'Giường lớn',
    code: 'KB',
    description: 'King-bed',
    type: null,
    icon: 'https://res.cloudinary.com/dj5wdbjzu/image/upload/v1688696091/70506213-1388-42e1-9784-fb369d89d3d7_King_yvowme.png',
    order: 0,
  },
]
