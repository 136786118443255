import React, { useEffect, useState } from 'react'
import { getAllData } from '../../services/dataProvider'

const BookingHistory = () => {
  const [bookingHistory, setBookingHistory] = useState<any>([])

  // loading
  const [openLoading, setOpenLoading] = useState(false)
  const fetchData = async () => {
    const userData =
      localStorage.getItem('userInfo') &&
      JSON.parse(localStorage.getItem('userInfo') || '')
    setOpenLoading(true)
    console.log('userData', userData)
    await getAllData(
      'booking-service',
      {
        customerId: userData?.id,
      },
      (res) => {
        console.log('history service: ', res.data.data)
        setBookingHistory(res.data.data)
      }
    )
    setOpenLoading(false)
  }
  useEffect(() => {
    fetchData()
  }, [])
  return <div>BookingHistory
    {bookingHistory.map((service: { roomTypeId: any }) => {
      return service.roomTypeId
    })}
  </div>
}

export default BookingHistory
