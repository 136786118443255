import React from 'react';
import { Col, Row } from 'react-bootstrap';
import '../styles/MyPromotionCard.scss';

const MyPromotionCard = () => {
	return (
		<div className='my-promotion-card'>
			<div className='content'>
				<Row>
					<Col md={4} sm={6}>
						<div className='image'>
							<img
								src='https://booking-static.vinpearl.com/hotels/d0ab92211ae54c5c9f68f11e4855b39a_VPDSWWPQ1.jpg'
								alt='booking1'
							/>
						</div>
					</Col>
					<Col md={8} sm={6}>
						<div className='coupon-info'>
							<h5>GIẢM 5% HAPPY COMBO TRÊN VINWONDERS.COM</h5>
							<p>07/04/2023 - 31/08/2023</p>
							<div>
								<button>Chi tiết</button>
							</div>
							<div className='use-coupon'>
								<button>Sử dụng</button>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default MyPromotionCard;
