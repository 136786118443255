import axios, { AxiosInstance } from 'axios'
import queryString from 'query-string'
const config = {
  headers: {
    'Content-Type': 'application/json',
    'x-correlation-id': '1',
    'x-api-key': '1',
    'Access-Control-Allow-Origin': '*',
  },
  setTimeout: 60000,
}
export const getApi = () => {
  let API: AxiosInstance
  API = axios.create({
    baseURL: `http://localhost:7081/api`,
    headers: config.headers,
  })
  return API
}
const API = getApi()
const getData = async (
  target: string,
  body?: any,
  callback?: (response: any) => void
) =>
  await API.post(`get-${target}`, body, config).then((res) => {
    callback && callback(res)
  })

const getAllData = async (
  target: string,
  body?: any,
  callback?: (response: any) => void
) => {
  await API.post(`get-all-${target}`, body, config).then((res) => {
    callback && callback(res)
  })
}

const updateData = async (
  target: string,
  body?: any,
  callback?: (response: any) => void
) =>
  await API.post(`update-${target}`, body, config).then((res) => {
    callback && callback(res)
  })

const deleteData = async (
  target: string,
  body?: any,
  callback?: (response: any) => void
) =>
  await API.post(`delete-${target}`, body, config).then((res) => {
    callback && callback(res)
  })

const createData = async (
  target: string,
  body?: any,
  callback?: (response: any) => void
) =>
  await API.post(`create-${target}`, body, config).then((res) => {
    callback && callback(res)
  })

const customRequest = async (
  target: string,
  body?: any,
  callback?: (response: any) => void
) =>
  await API.post(`reset-${target}`, body, config).then((res) => {
    callback && callback(res)
  })

const search = async (
  target: string,
  body?: any,
  callback?: (response: any) => void
) =>
  await API.get(`get-${target}?${queryString.stringify(body)}`, config).then(
    (res) => {
      callback && callback(res)
    }
  )

export {
  getData,
  createData,
  updateData,
  deleteData,
  customRequest,
  getAllData,
  search,
}
