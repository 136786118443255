import React from 'react';
import MyPromotionCard from './MyPromotionCard';


function MyPromotion() {
	return (
		<div>
			<h5>Ưu đãi của tôi</h5>
			<div>
				<MyPromotionCard />
        <MyPromotionCard />
        <MyPromotionCard />
        <MyPromotionCard />
			</div>
		</div>
	);
}

export default MyPromotion;
