import '../assets/style/LoginPage.scss'

import React, { useState } from 'react'
import { ELoginPage } from '../data/enum/ELoginPage'
import { useNavigate } from 'react-router-dom'
import { EApi, EServerity, EStatusCode, MESSAGE, PagePath } from '../data/constants'
import { v4 as uuidv4 } from 'uuid'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import { createData, customRequest, getData } from '../services/dataProvider'
import { Base64 } from 'js-base64'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { BaseInput } from '../components/form/BaseInput'
import { validateEmail, validatePhone } from '../utils/ultils'
import { FormProvider } from '../components/form/FormProvider'
import { useForm } from 'react-hook-form'
import { BaseButton } from '../components/form/BaseButton'
import BaseCheckBox from '../components/form/BaseCheckbox'
import { Backdrop, CircularProgress } from '@mui/material'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const LoginPage = () => {
  const navigate = useNavigate()
  const [formType, setFormType] = useState(ELoginPage.signIn)
  const [wrongInputCount, setWrongInputCount] = useState(0)
  //form

  const methods = useForm<any>({})
  const { handleSubmit, getValues, reset } = methods

  // loading
  const [openLoading, setOpenLoading] = useState(false)

  //snackbar
  const [openMessage, setOpenMessage] = useState(false)
  const [meesageError, setMessageError] = useState('')
  const [serverity, setServerity] = useState('')
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenMessage(false)
  }
  //end snackbar

  const onSubmit = async (data: any) => {
    switch (formType) {
      case ELoginPage.signIn:
        setOpenLoading(true)
        if (wrongInputCount < 3) {
          const encodedData = Base64.encode(data?.password)
          await getData(
            EApi.userLogin,
            {
              ...data,
              password: encodedData,
              confirmPassword: encodedData,
            },
            (res: any) => {
              if (res.data?.statusCode === EStatusCode.SUCCESS) {
                const dataUser = res.data.data
                setMessageError('Đăng nhập thành công.')
                setServerity(EServerity.SUCCESS)
                setOpenMessage(true)
                const userInfor = JSON.stringify({
                  id: dataUser?.id,
                  userName: dataUser?.userName,
                  email: dataUser?.email,
                  phoneNumber: dataUser?.phoneNumber,
                  token: dataUser?.token,
                  dateOfBirth: dataUser?.dateOfBirth,
                  address: dataUser?.address,
                  membershipPoint: dataUser?.membershipPoint,
                  nationality: dataUser?.nationality,
                  membershipLevel: dataUser?.membershipLevel,
                  gender: dataUser?.gender,
                })
                localStorage.setItem('userInfo', userInfor)
                setTimeout(() => {
                  navigate(PagePath.Home)
                  reset()
                }, 1000)
              } else if (res.data?.statusCode === EStatusCode.INTERNAL_SERVER_ERROR) {
                setMessageError('Lỗi hệ thống.')
                setServerity(EServerity.ERROR)
                setOpenMessage(true)
              } else {
                setMessageError(res.data?.errorMessage)
                setServerity(EServerity.ERROR)
                setOpenMessage(true)
              }
            }
          )
          // if (password === '123456') {
          //   const token = uuidv4()
          //   console.log('logged in successfully ' + token)
          //   localStorage.setItem('userToken', token)
          //   navigate(PagePath.Home)
          // }
        } else {
          setFormType(ELoginPage.forgotPassword)
        }
        setOpenLoading(false)
        break
      case ELoginPage.signUp:
        setOpenLoading(true)
        const encodedData = Base64.encode(data?.password)
        await createData(
          EApi.user,
          { ...data, password: encodedData },
          (res: any) => {
            if (res.data?.statusCode === EStatusCode.SUCCESS) {
              setMessageError('Đăng ký thành công.')
              setServerity(EServerity.SUCCESS)
              setOpenMessage(true)
              setTimeout(() => {
                window.location.reload()
              }, 1000)
              reset()
            } else if (res.data?.statusCode === EStatusCode.INTERNAL_SERVER_ERROR) {
              setMessageError('Lỗi hệ thống.')
              setServerity(EServerity.ERROR)
              setOpenMessage(true)
            } else {
              setMessageError(res.data?.errorMessage)
              setServerity(EServerity.ERROR)
              setOpenMessage(true)
            }
          }
        )
        setOpenLoading(false)
        break
      case ELoginPage.forgotPassword:
      // customRequest('resetPassword', { email, phoneNumber })
    }
  }
  const signInForm = (
    <>
      <div className='sign-in'>
        <BaseInput
          type='number'
          name='userName'
          placeholder='Số điện thoại'
          sx={{ marginTop: '10px' }}
          icon={<PhoneAndroidOutlinedIcon />}
          rules={{
            validate: (value: any) => {
              if (!value) return MESSAGE.REQUIRED
              if (!validatePhone(value)) return MESSAGE.FORMAT_PHONE
              if (value.length < 9) return MESSAGE.LENGHT_FORMAT_PHONE
            },
          }}
        />
        <BaseInput
          type='password'
          icon={<LockOpenOutlinedIcon />}
          name='password'
          placeholder='Mật khẩu'
          sx={{ marginTop: '10px' }}
          rules={{
            validate: (value: any) => {
              if (!value) return MESSAGE.REQUIRED
            },
          }}
        />
        <div className='custom-login-btn'>
          <BaseButton type='submit' variant='contained' color='error'>
            Đăng nhập
          </BaseButton>
        </div>
        <div className='options'>
          <div className='option1'>
            <button
              className='customBtn'
              onClick={(e) => {
                e.preventDefault()
                setFormType(ELoginPage.forgotPassword)
                reset()
              }}
            >
              Quên mật khẩu
            </button>
          </div>
          <div className='option2'>
            {/* <button
							className='customBtn'
							onClick={(e) => {
								e.preventDefault();
								setFormType(ELoginPage.signUp);
							}}>
							Đăng ký
						</button> */}
          </div>
        </div>
        <div className='customTxt'>
          <p>Không có tài khoản?</p>
          <button
            className='customBtn'
            onClick={(e) => {
              e.preventDefault()
              setFormType(ELoginPage.signUp)
              reset()
            }}
          >
            Đăng ký
          </button>
        </div>
      </div>
    </>
  )
  const signUpForm = (
    <div className='sign-up'>
      <BaseInput
        type='text'
        name='userName'
        placeholder='Họ và tên'
        sx={{ marginTop: '10px' }}
        icon={<GroupOutlinedIcon />}
        rules={{
          validate: (value: any) => {
            if (!value) return MESSAGE.REQUIRED
          },
        }}
      />
      <BaseInput
        type='number'
        name='phoneNumber'
        placeholder='Số điện thoại'
        sx={{ marginTop: '10px' }}
        icon={<PhoneAndroidOutlinedIcon />}
        rules={{
          validate: (value: any) => {
            if (!value) return MESSAGE.REQUIRED
            if (!validatePhone(value)) return MESSAGE.FORMAT_PHONE
            if (value.length < 9) return MESSAGE.LENGHT_FORMAT_PHONE
          },
        }}
      />
      <BaseInput
        type='text'
        name='email'
        placeholder='Email'
        icon={<EmailOutlinedIcon />}
        sx={{ marginTop: '10px' }}
        rules={{
          validate: (value: any) => {
            if (!value) return MESSAGE.REQUIRED
            if (!validateEmail(value)) return MESSAGE.EMAIL
          },
        }}
      />
      <BaseInput
        type='password'
        icon={<LockOpenOutlinedIcon />}
        name='password'
        placeholder='Mật khẩu'
        sx={{ marginTop: '10px' }}
        rules={{
          validate: (value: any) => {
            if (!value) return MESSAGE.REQUIRED
          },
        }}
      />
      <BaseInput
        type='password'
        name='confirmPassword'
        sx={{ marginTop: '10px' }}
        icon={<LockOpenOutlinedIcon />}
        rules={{
          validate: (value: any) => {
            if (!value) return MESSAGE.REQUIRED
            if (value !== getValues('password')) return MESSAGE.CONFIRM_PASSWORD
          },
        }}
        placeholder='Xác nhận mật khẩu'
      />
      <BaseCheckBox
        name='rememberPassword'
        label='Nhớ mật khẩu'
        sx={{ color: 'whitesmoke' }}
      ></BaseCheckBox>
      <div className='custom-signup-btn'>
        <BaseButton type='submit' variant='contained'>
          Đăng ký
        </BaseButton>
      </div>
      <div className='customTxt'>
        <p>Đã có tài khoản?</p>
        <button
          onClick={(e) => {
            e.preventDefault()
            setFormType(ELoginPage.signIn)
            reset()
          }}
        >
          Sign In
        </button>
      </div>
    </div>
  )
  const forgotPasswordForm = (
    <>
      <div className='forgot-password'>
        <h4>Quên mật khẩu?</h4>
        <div className='custom-input'>
          <EmailOutlinedIcon />{' '}
          <input type='email' name='email' placeholder='Email' />
        </div>
        <div className='custom-forget-btn'>
          <input type='button' name='forgotPassword' value='Xác nhận' />
        </div>
        <div className='customTxt'>
          <p>Không có tài khoản?</p>
          <button
            onClick={(e) => {
              e.preventDefault()
              setFormType(ELoginPage.signUp)
              reset()
            }}
          >
            Đăng ký
          </button>
        </div>
      </div>
    </>
  )

  const resetPasswordForm = (
    <>
      <div className='forgot-password'>
        <h4>Đặt lại mật khẩu?</h4>
        <div>
          <input type='password' name='password' placeholder='Mật khẩu mới' />
        </div>
        <div>
          <input
            type='password'
            name='password'
            placeholder='Xác nhận mật khẩu mới'
          />
        </div>
        <div>
          <input
            onSubmit={(e) => {
              onSubmit(e)
            }}
            name='resetPassword'
            value='Gửi'
          />
        </div>
      </div>
    </>
  )

  return (
    <div className='login-page'>
      <div className='custom-padding-top'>&nbsp;</div>
      <div className='login-form'>
        <FormProvider method={methods} onSubmit={handleSubmit(onSubmit)}>
          {formType === ELoginPage.signIn ? signInForm : undefined}
          {formType === ELoginPage.signUp ? signUpForm : undefined}
          {formType === ELoginPage.forgotPassword
            ? forgotPasswordForm
            : undefined}
          {formType === ELoginPage.resetPassword
            ? resetPasswordForm
            : undefined}
        </FormProvider>
      </div>
      <div className='custom-padding-bottom'>&nbsp;</div>
      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={serverity as any}
          sx={{ width: '100%' }}
        >
          {meesageError}
        </Alert>
      </Snackbar>
      <Backdrop open={openLoading} sx={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress size={100} color='inherit' />
      </Backdrop>
    </div>
  )
}

export default LoginPage
