import React, { useEffect, useState } from 'react'
import ServicePromo from '../features/service/components/ServicePromo'
import useAppStore from '../store/appStore'

export const Services = () => {
  const [isLogged, setIsLogged] = useState(false)
  const [userToken, setUserToken] = useState('')
  const { currentPage } = useAppStore((state) => state)
  useEffect(() => {
    if (currentPage?.includes('logout')) {
      localStorage.removeItem('userInfo')
      setUserToken('')
    }
    const dataUser =
      localStorage.getItem('userInfo') &&
      JSON.parse(localStorage.getItem('userInfo') || '')

    if (dataUser?.token) {
      setIsLogged(true)
      setUserToken(dataUser?.token || '')
    }
  }, [])

  return (
    <>
      {!isLogged ? (
        <ServicePromo />
      ) : (
        <>
          <ServicePromo />
        </>
      )}
    </>
  )
}
