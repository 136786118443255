import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { FC, useEffect, useState } from 'react'
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import { BaseButton } from '../../../components/form/BaseButton'
import DialogCommon from '../../../components/form/DialogCommon'
import { LIST_AMENITY_ROOM } from '../../../data/constants'
import { SelectedRoom } from '../../../data/type'
import styled from '../styles/Room.module.scss'
import PackageOffer from './PackageOffer'
import { currencyFormat } from '../../../services/stringUtil'

interface RoomListProps {
  roomList?: any
  selectRoomList?: SelectedRoom[]
  callSetSelectedRoom: (selectedRoom: SelectedRoom) => void
  bookingName?: string
}

const RoomList: FC<RoomListProps> = (props) => {
  const {
    bookingName = '',
    roomList,
    selectRoomList = [],
    callSetSelectedRoom,
  } = props

  const isMember = !!(
    localStorage.getItem('userInfo') &&
    JSON.parse(localStorage.getItem('userInfo') || '')
  )
  const [selectedId, setSelectedId] = useState('')
  const [availableRoomList, setAvailableRoomList] = useState(roomList)
  const [openDetail, setOpenDetail] = useState(false)

  const selectRoom = (roomId: string) => {
    setSelectedId(roomId)
  }

  const getPrice = (roomId: string, ratePlanId: string, listRoom: any) => {
    if (!listRoom || listRoom.length < 1) return
    const roomInfo = listRoom.find((room: any) => room.id === selectedId)
    const plan = roomInfo?.roomRates.find(
      (plan: any) => plan.ratePlanId === ratePlanId
    )
    if (isMember) {
      return plan?.memberRate || 0
    }
    return plan?.totalAmount || 0
  }

  const selectOffer = (offer: string) => {
    const price = getPrice(selectedId, offer, roomList)
    const selectedRoom = {
      bookingName: bookingName,
      id: selectedId,
      roomRate: offer,
      price,
    }
    callSetSelectedRoom(selectedRoom)
  }

  useEffect(() => {
    const selectedRoomObject = selectRoomList.filter(
      (room) => room.bookingName !== bookingName
    )
    const selectedRooms = selectedRoomObject.map((room) => room.id)

    const availableList = roomList.filter(
      (room: any) => !selectedRooms.includes(room.id)
    )
    setAvailableRoomList(availableList)
  }, [selectRoomList])

  const findRoom = (id: string) => {
    const index = availableRoomList?.findIndex(
      (element: any) => element.id === id
    )
    if (index < 0) return {}
    return availableRoomList[index]
  }
  console.log(findRoom(selectedId))
  return availableRoomList?.map((room: any, idx: number) => (
    <div className={styled.room} key={idx}>
      <Row>
        <Col>
          <Carousel>
            {room.images?.map((image: any, idx: number) => {
              return (
                <Carousel.Item key={idx}>
                  <img
                    className='d-block w-100'
                    src={image}
                    alt={idx.toString()}
                  />
                </Carousel.Item>
              )
            })}
          </Carousel>
        </Col>
        <Col>
          <div>
            <div>
              <h5>{room?.name}</h5>
            </div>
            {room.shortDescription}
            {selectedId === room.id ? (
              <div>
                <BaseButton
                  text={'Xem phòng'}
                  onClick={() => {
                    setOpenDetail(true)
                  }}
                >
                  Xem phòng
                </BaseButton>
              </div>
            ) : (
              <div className={styled.priceInfo}>
                <div>
                  Giá công bố{' '}
                  <span className={styled.price}>
                    {currencyFormat(room.totalAmount, 'vi')} vnđ
                  </span>
                </div>
                <div>
                  Giá thành viên{' '}
                  <span className={styled.price}>
                    <b>{currencyFormat(room.memberRate, 'vi')} vnđ</b>
                  </span>
                </div>
                <div>
                  <p>/đêm</p>
                </div>
                <div>
                  <div>
                    <Button
                      onClick={() => {
                        selectRoom(room.id)
                      }}
                    >
                      Chọn
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      {selectedId === room.id ? (
        <Row>
          <Col>
            <Tabs
              defaultActiveKey='standard'
              id='uncontrolled-tab-example'
              className='mb-3'
            >
              <Tab eventKey='standard' title='Gói tiêu chuẩn'>
                <div className={styled.packageOffer}>
                  <PackageOffer
                    roomId={room.id}
                    callSelectedOffer={selectOffer}
                    offerList={room.roomRates}
                  />
                </div>
              </Tab>
              {/* <Tab eventKey='specific' title='Gói độc quyền'>
								<PackageOffer
                  roomId='r4'
                  selectedId={selectedId}
                  callSetSelectedRoom={setSelectedRoom}
                />
							</Tab> */}
            </Tabs>
          </Col>
        </Row>
      ) : (
        ''
      )}
      <DialogCommon
        maxWidth='md'
        open={openDetail}
        setOpen={setOpenDetail}
        onDisAgreeClick={() => setOpenDetail(false)}
        onAgreeClick={() => setOpenDetail(false)}
        hasAgree={false}
        scroll={'body'}
        title={'LE BRUDER HỒNG THÁI'}
      >
        <>
          <div>
            <>
              {findRoom(selectedId)?.descriptions?.map((desc: any) => (
                <li>{desc}</li>
              ))}
            </>
          </div>
          {
            <Carousel slide={false}>
              {findRoom(selectedId)?.images?.map((image: any, idx: number) => {
                return (
                  <Carousel.Item key={idx}>
                    <img
                      className='d-block w-100'
                      src={image}
                      alt={idx.toString()}
                    />
                  </Carousel.Item>
                )
              })}
            </Carousel>
          }
          <h5 style={{ marginTop: '10px' }}> {findRoom(selectedId)?.name}</h5>
          <span>{findRoom(selectedId)?.description}</span>
          <h6 style={{ marginTop: '10px' }}>Tiện ích</h6>
          <Box sx={{ width: '100%' }}>
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction='row'
              useFlexGap
              flexWrap='wrap'
            >
              {LIST_AMENITY_ROOM?.map((amenity: any) => (
                <Paper sx={{ boxShadow: 'none', width: '200px' }}>
                  <img
                    key={amenity?.id}
                    height={30}
                    width={30}
                    src={amenity?.icon}
                    style={{ marginRight: '10px' }}
                  />
                  {amenity?.name}
                </Paper>
              ))}
            </Stack>
          </Box>
        </>
      </DialogCommon>
    </div>
  ))
}

export default RoomList
