import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import * as React from 'react'

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function DialogCommon(props: any) {
  const {
    children,
    open,
    setOpen,
    onAgreeClick,
    onDisAgreeClick,
    title,
    hasAgree = true,
    ...other
  } = props

  return (
    <div>
      <Dialog aria-labelledby='customized-dialog-title' open={open} {...other}>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={() => setOpen(false)}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {hasAgree && (
            <Button
              autoFocus
              onClick={() => {
                onAgreeClick()
                setOpen(false)
              }}
            >
              Lưu
            </Button>
          )}
          <Button
            autoFocus
            onClick={() => {
              onDisAgreeClick()
              setOpen(false)
            }}
          >
            Hủy
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
