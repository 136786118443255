import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import DialogCommon from '../../../components/form/DialogCommon'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { useState } from 'react'
const Service = (props: any) => {
  const { data } = props
  const [openDetail, setOpenDetail] = useState(false)
  const [idService, setIdService] = useState('')
  const findService = (id: string) => {
    const index = data?.findIndex((element: any) => element.id === id)
    if (index < 0) return {}
    return data[index]
  }

  return (
    <>
      {data?.map((item: any) => (
        <div
          key={item?.id}
          style={{ marginRight: '5px' }}
          onClick={() => {
            setOpenDetail(true)
            setIdService(item?.id)
          }}
        >
          <Card style={{ width: '18rem', height: 650 }}>
            <Card.Img variant='top' srcSet={`${item.images[0]}`} />
            <Card.Body>
              <Card.Title>{item?.serviceName}</Card.Title>
              <Card.Text>
                <p>{item?.shortDescription}</p>

                <div>
                  <label>Thời gian: {item?.schedule}</label>
                </div>
                <div>
                  <label>Giá: {item?.price}</label>
                </div>
                {/* <div>
                  <label>{item?.availability}</label>
                </div>
                <div>
                  <label>{item?.additionalInfo}</label>
                </div> */}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      ))}
      <DialogCommon
        maxWidth='lg'
        open={openDetail}
        setOpen={setOpenDetail}
        onDisAgreeClick={() => setOpenDetail(false)}
        onAgreeClick={() => setOpenDetail(false)}
        hasAgree={false}
        title={findService(idService).serviceName}
      >
        <>
          <div>
            <>
              {findService(idService)?.descriptions?.map((desc: any) => (
                <li>{desc}</li>
              ))}
            </>
          </div>
          {findService(idService) && (
            <ImageList sx={{ height: 600 }} cols={3} rowHeight={164}>
              {findService(idService) &&
                findService(idService).images?.map((i: any, index: any) => (
                  <ImageListItem key={index}>
                    <img
                      src={`${i}`}
                      srcSet={`${i}`}
                      alt={findService(idService).serviceName}
                      loading='lazy'
                    />
                  </ImageListItem>
                ))}
            </ImageList>
          )}
          <h5>Thời gian sử dụng dịch vụ: {findService(idService)?.schedule}</h5>
          <h5>Giá: {findService(idService)?.price}</h5>
        </>
      </DialogCommon>
    </>
  )
}

export default Service
