import "../assets/style/ContactUs.scss";
import LBMap from "../components/ui/LBMap";

const ContactUs = () => {
  return (
    <div className="contact-us">
      <p className="title">
        <h4>Le Bruder Glamping</h4>
      </p>
      <div className="content">
        <div>
          <h6>Văn phòng Le Bruder tại Hà Nội</h6>
          <p>Bản Lếch Dao, Thanh Bình, huyện Sapa, tỉnh Lào Cai.</p>
        </div>
        <div>
          <h6>Văn phòng Le Bruder tại Hà Nội</h6>
          <p>
            BT1B-A305, Khu đô thị Mễ Trì Thượng, Phường Mễ Trì, Quận Nam Từ
            Liêm, Hà Nội, Việt Nam
          </p>
        </div>
        <div>
          <p>
            <b>Email: </b>info@lebruder.com
          </p>
          <p>
            <b>Liên hệ: </b>0901 23 05 22
          </p>
        </div>
        <b>Gửi mail cho chúng tôi</b>
        <div className="submit-form">
          <div>
            <label htmlFor="name">Tên</label>
            <br />
            <input type="text" name="userName" />
          </div>
          <div>
            <label htmlFor="name">Email</label>
            <br />
            <input type="email" name="userMail" />
          </div>
          <div>
            <label htmlFor="name">Tiêu đề</label>
            <br />
            <input type="text" name="title" />
          </div>
          <div>
            <label htmlFor="name">Lời nhắn</label>
            <br />
            <textarea name="message" id="msg" cols={30} rows={10}></textarea>
          </div>
          <div>
            <input type="submit" value="Gửi email" />
          </div>
        </div>
      </div>
      <div className="map-view">
          <p className="title">
            <h4>Fog Oasis Hồng Thái</h4>
          </p>
          <div className="map">
            <LBMap />
          </div>
        </div>
        <div className="map-view">
          <p className="title">
            <h4>Le Bruder tại Hà Nội</h4>
          </p>
          <div className="map">
            <LBMap />
          </div>
        </div>
    </div>
  );
};

export default ContactUs;
