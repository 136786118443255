import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { StyledTooltip } from './CustomTooltip'

export const BaseDatePicker = (props: any) => {
  const {
    name,
    rules,
    label,
    required,
    helperText,
    defaultValue,
    onDateChange,
    isShowMessage = true,
    showErrorMessage = true,
    views,
    ...other
  } = props
  const { control, setValue } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            {...field}
            value={field?.value || null}
            label={
              label &&
              (required ? (
                <>
                  {label}
                  <span className='text-red ml-3'>* </span>
                </>
              ) : (
                <>{label}</>
              ))
            }
            aria-describedby='component-error-text'
            views={views}
            sx={{ marginTop: '10px', marginBottom: '10px' }}
            fullWidth
            slotProps={{
              textField: {
                error: !!error,
                helperText: error ? error?.message : helperText,
              },
            }}
            {...other}
          />
        </LocalizationProvider>
      )}
      {...other}
    />
  )
}
