import React from 'react';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';

function MemberOffer() {
	return (
		<div className='member-offer'>
			<h5>
				<LuggageOutlinedIcon />
				Ưu đãi giá phòng khách sạn Le Bruder Glamping trên giá tốt nhất
			</h5>
			<p>
				Áp dụng đặt online trên Website Le Bruder Glamping.com, app Le Bruder
				Glamping hoặc đặt trực tiếp tại các cơ sở
			</p>
			<label>10 %</label>
		</div>
	);
}

export default MemberOffer;
