import styled from "../assets/style/PhotoCard.module.scss";
const PhotoCard = ({
  url,
  title,
  showTitle,
}: {
  url: string;
  title: string;
  showTitle?: boolean;
}) => {
  return (
    <div className={styled.photoCard}>
      <img src={url} alt={title} />
      <p>{ showTitle ? title : ''}</p>
    </div>
  );
};
export default PhotoCard;
