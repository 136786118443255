import styled from '../styles/SpaService.module.scss'
const SpaService = () => {
  return (
    <div className={styled.spaService}>
        <div className={styled.title}>Tận hưởng dịch vụ spa</div>
      <img
        src="https://i.ibb.co/ky27Vtz/spa.png"
        alt="spa"
      />
    </div>
  );
};
export default SpaService;
