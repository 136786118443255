import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PhotoCard from '../../../components/PhotoCard'
import styled from '../styles/Section.module.scss'
import ButtonLink from '../../../components/ui/ButtonLink'
import OverallMultiItemCarousel from '../../../components/ui/OverallMultiItemCarousel'
import { overViewMultiItemCarouselResponsive } from '../../../data/constants'
import { introductionImg } from '../../../data/media'
const Introduction = () => {
  const { t } = useTranslation()
  const readMore = () => {}
  const introPhotos = introductionImg.map((photo) => {
    return <PhotoCard showTitle={false} url={photo.url} title={photo.title} />
  })
  return (
    <div className={styled.section}>
      <div className={styled.title}>
        <p>{t('content.introduce.overView')}</p>
      </div>
      <Row>
        <Col lg={5} md={5} sm={12}>
          <h3 className={styled.subTitle}>Fog oasis - Ốc đảo mù sương</h3>
          <article>
            <p>
              Sức mạnh hấp dẫn nhất ở Hồng Thái là vào mùa xuân, hoa lê, hoa mận
              rực rỡ màu trắng tinh, những vườn cải những vườn cải vàng bung nở
              bạt ngàn, tô điểm cho không gian núi rừng vẻ mơ mộng khó cưỡng,
              mùa hè Hồng Thái được tô điểm bởi những thửa ruộng bậc thang xanh
              mướt vắt ngang lưng đồi. Đến Hồng Thái mùa thu bạn sẽ phải chuếnh
              choáng bởi vẻ đẹp của những thửa ruộng bậc thang chín vàng óng,
              tầng tầng, lớp lớp sóng sánh và toả mùi hương lúa mới hay những
              vườn lê chín mọng ngọt say mê. Mùa đông Hồng Thái lại trở nên thật
              huyền ảo trong màn sương giăng và những tầng mây sà xuống bên vạt
              rừng, trên từng nóc nhà xinh.
            </p>
          </article>
          <div>
            <ButtonLink
              text={t('content.common.readMore')}
              href='/ourStories'
            />
          </div>
        </Col>
        <Col lg={7} md={7} sm={12}>
          <div className={styled.image}>
            <OverallMultiItemCarousel
              responsive={overViewMultiItemCarouselResponsive}
              itemList={introPhotos}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Introduction
