import { PagePath } from '../data/constants';
import { useTranslation } from 'react-i18next';
import '../assets/style/Header.scss';
import useAppStore from '../store/appStore';
import LBNavbar from './LBNavbar';
import { headerMedia } from '../data/media';
import { useEffect, useState } from 'react';
const Header = () => {
	const { t } = useTranslation();
	const [isHideVideo, setIsHideVideo] = useState(false);
  const [mouseIn, setMouseIn] = useState(false);
	const currentPage = useAppStore((state) => state.currentPage);

	let header1 = (
		<div className='header1'>
			<LBNavbar showNavbar mouseIn={mouseIn} />
			{!isHideVideo ? (
				<div className='mainContain'>
					<div className='headerVideo'>
						<video
							id='banner-vid'
							autoPlay
							muted
							loop
							src={headerMedia.video}></video>
					</div>
					<div className='groupText'>
						<div className='groupText1'>
							<p className='text1'>{t('content.header.text1')}</p>
							<p className='text2'>{t('content.header.text2')}</p>
						</div>
						<div className='groupText2'>
							<p className='headerText'>Le Bruder Hồng Thái</p>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
	const header2 = (
		<div className='header2'>
			<LBNavbar showNavbar />
		</div>
	);

	const noHeader = (
		<div className='header3'>
			<LBNavbar showNavbar={false} />
		</div>
	);

	let header = header1;
	if (currentPage?.includes(PagePath.ContactUs)) {
		setIsHideVideo(false);
		header = header2;
	} else if (currentPage?.includes(PagePath.Login)) {
		header = noHeader;
	} else if (currentPage?.includes(PagePath.Accommodations)) {
	}

  const watchMouse = (e: any) => {
    setMouseIn(e.type === 'mouseover');
    // console.log("onMouseOver: ", e);
  }

	useEffect(() => {
		if (currentPage?.includes(PagePath.Accommodations)) {
			setIsHideVideo(true);
		} else {
			setIsHideVideo(false);
		}
	}, [currentPage]);
	return <div onMouseOver={watchMouse} onMouseOut={watchMouse}>{header}</div>;
};
export default Header;
