import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const BaseRadioGroup = (props: any) => {
  const {
    name,
    label,
    options,
    helpertext,
    required,
    defaultValue,
    row,
    sx,
    ...other
  } = props
  const { control } = useFormContext()
  const labelledby = label ? `${name}-${label}` : ''
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControl sx={{ ...sx, marginTop: '10px' }}>
            {label && (
              <FormLabel id={labelledby}>
                {label}
                {required ? <span className='text-red ml-3'>*</span> : <></>}
              </FormLabel>
            )}
            <RadioGroup
              {...field}
              aria-labelledby={labelledby}
              row={row}
              value={field.value || ''}
              {...other}
            >
              {options?.map((option: any) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      )}
    />
  )
}
