import { Col, Row } from "react-bootstrap";
import styled from "../assets/style/Footer.module.scss";
import { useTranslation } from "react-i18next";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={styled.footer}>
      <Row>
        <Col md={5} sm={12} xs={12}>
          <div className={styled.col1}>
            <div className={styled.logo}>
              <img
                src={require("../assets/images/logo-LeBruder-01.png")}
                alt="logo"
              />
            </div>
            <div>
              <h4>CÔNG TY CỔ PHẦN LE BRUDER</h4>
              <div>
                <span>
                  <LocationOnOutlinedIcon />
                </span>
                Trụ sở chính: Số 64, Ngõ 102, Đường Trường Chinh, Phường Phương
                Mai, Quận Đống Đa, Hà Nội, Việt Nam
              </div>
              <div>
                <span>
                  <LocationOnOutlinedIcon />
                </span>
                Văn phòng đại diện: BT1B-A305, Khu đô thị Mễ Trì Thượng, Phường
                Mễ Trì, Quận Nam Từ Liêm, Hà Nội, Việt Nam
              </div>
              <a href="tel:+84901230522">
                <div>
                  <LocalPhoneOutlinedIcon />
                  0901 23 05 22
                </div>
              </a>
              <div>
                <a href="mailto:info@lebruder.com">
                  <span>
                    <EmailOutlinedIcon />
                  </span>{" "}
                  info@lebruder.com
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col md={7} sm={12} xs={12}>
          <div className={styled.col}>
            <Row>
            <Col sm={12} xs={12} md={6}>
                <p className={styled.title}>Le Bruder Glamping</p>
                <ul>
                  <li>
                    <a href="http://">{t("content.footer.aboutUs")}</a>
                  </li>
                  <li>
                    <a href="http://">{t("content.common.contactUs")}</a>
                  </li>
                  <li>
                    <a href="http://">{t("content.footer.jobOpportunity")}</a>
                  </li>
                  <li>
                    <a href="http://">{t("content.footer.siteMap")}</a>
                  </li>
                  <li>
                    <a href="http://">{t("content.footer.favoritePoint")}</a>
                  </li>
                </ul>
              </Col>
              <Col sm={12} xs={12} md={6}>
                <p className={styled.title}>{t("content.footer.policiesAndRules")}</p>
                <ul>
                  <li>
                    <a href="http://">{t("content.footer.rule")}</a>
                  </li>
                  <li>
                    <a href="http://">{t("content.footer.regulations")}</a>
                  </li>
                  <li>
                    <a href="http://">
                      {t("content.footer.paymentRegulations")}
                    </a>
                  </li>
                  <li>
                    <a href="http://">
                      {t("content.footer.bookingRegulations")}
                    </a>
                  </li>
                  <li>
                    <a href="http://">
                      {t("content.footer.conflictionPolicy")}
                    </a>
                  </li>
                  <li>
                    <a href="http://">{t("content.footer.privacyPolicy")}</a>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={6}>
                <p className={styled.title}>{t("content.footer.favoritePoint")}</p>
                <div>
                  <ul>
                    <li>
                      <a href="http://">Glamping Hồng Thái</a>
                    </li>
                    <li>
                      <a href="http://">Glamping Điện Biên</a>
                    </li>
                    <li>
                      <a href="http://">Glamping Cao Bằng</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col sm={12} xs={12} md={6}>
                <p className={styled.title}>{t("content.footer.tag")}</p>
                <div>
                  <ul>
                    <li>
                      <a href="http://">Tuyên Quang</a>
                    </li>
                    <li>
                      <a href="http://">Cao Bằng</a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Footer;
