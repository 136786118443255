import * as React from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface BaseSelectProps {
  name: string
  label?: string
  title?: string
  options: any
}

const BaseSelect: FC<any> = (props) => {
  const {
    value = undefined,
    name,
    label,
    rules,
    required,
    helperText,
    defaultValue,
    icon,
    options,
    personName,
    sx,
    ...other
  } = props
  const { control } = useFormContext()
  const theme = useTheme()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id={`id-${name}-label`}>{label}</InputLabel>
          <Select
            labelId={`id-${name}-label`}
            {...field}
            label={
              label &&
              (required ? (
                <>
                  {label}
                  <span className='text-red ml-3'>* </span>
                </>
              ) : (
                <>{label}</>
              ))
            }
            MenuProps={MenuProps}
            error={!!error}
            {...other}
          >
            {options?.map((item: any) => (
              <MenuItem key={item?.key} value={item?.value}>
                {item?.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  )
}

export default BaseSelect
