import React, { useEffect, useState } from 'react'
import { BaseInput } from '../../../components/form/BaseInput'
import { FormProvider } from '../../../components/form/FormProvider'
import { useForm } from 'react-hook-form'
import { Col, Row } from 'react-bootstrap'
import { EApi } from '../../../data/constants'
import { BaseDatePicker } from '../../../components/form/BaseDatePicker'
import BaseSelect from '../../../components/form/BaseSelect'
import '../styles/MyBooking.scss'
import ShortBookingCard from './ShortBookingCard'
import { getAllData } from '../../../services/dataProvider'
import { Backdrop, CircularProgress } from '@mui/material'
import { getDDMMYYYY_HHmmss } from '../../../services/dateUtil'

export const MyBooking = () => {
  const methods = useForm<any>({})
  const { handleSubmit } = methods
  const [bookingList, setBookingList] = useState([])
  // loading
  const [openLoading, setOpenLoading] = useState(false)
  const dataUser =
    localStorage.getItem('userInfo') &&
    JSON.parse(localStorage.getItem('userInfo') || '')
  const fetchData = async () => {
    setOpenLoading(true)

    await getAllData(EApi.reservationByUserId, { id: dataUser?.id }, (res) => {
      console.log(res.data.data.list)
      setBookingList(res.data.data.list)
    })
    setOpenLoading(false)
  }
  useEffect(() => {
    dataUser?.token && fetchData()
  }, [])
  const onSubmit = () => {}
  return (
    <div className='my-booking'>
      <FormProvider method={methods} onSubmit={handleSubmit(onSubmit)}>
        <h5>Đặt chỗ của tôi</h5>
        <div>
          <div className='booking-filter'>
            <Row>
              <Col>
                <BaseInput
                  name='bookingId'
                  label='Mã đặt chỗ'
                  sx={{ marginTop: '10px' }}
                />
              </Col>
              <Col>
                <BaseDatePicker
                  name='monthOfBooking'
                  label='Tháng đặt'
                  views={['year', 'month']}
                  openTo='month'
                />
              </Col>
              {/* <Col>
								<BaseSelect name='serviceType' label='Loại dịch vụ' />
							</Col> */}
              <Col>
                <BaseSelect
                  name='bookingStatus'
                  label='Trạng thái'
                  options={[
                    { key: 'RESERED', value: 'Đặt thành công' },
                    { key: 'FAIL', value: 'Không thành công' },
                    { key: 'PAYMENT', value: 'Thanh toán thành công' },
                  ]}
                />
              </Col>
            </Row>
          </div>
          <div>
            <p>{`Có ${bookingList.length} kết quả`}</p>
            {bookingList.map((booking: any) => {
              return (
                <>
                  <p>{`Ngày đặt: ${getDDMMYYYY_HHmmss(booking.insDate)}`}</p>
                  {/* {booking.reservationDetails?.map((bookingDetail: any) => {
                    return ( */}
                  <ShortBookingCard
                    bookingDetail={booking?.reservationDetails}
                    bookingStatus={booking?.status}
                    totalPrice={booking?.totalAmount}
                    id={booking?.id}
                  />
                  {/* )})} */}
                </>
              )
            })}
          </div>
        </div>
      </FormProvider>
      <Backdrop open={openLoading} sx={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress size={100} color='inherit' />
      </Backdrop>
    </div>
  )
}
