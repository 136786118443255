import { Col, Row } from 'react-bootstrap'
import styled from '../styles/PackageOffer.module.scss'
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined'
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined'
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined'
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { BaseButton } from '../../../components/form/BaseButton'
import { currencyFormat } from '../../../services/stringUtil'

interface PackageOfferProps {
  callSelectedOffer?: Function
  offerList: any
  roomId: string
}

const PackageOffer: FC<PackageOfferProps> = (props) => {
  const [selectedOffer, setSelectedOffer] = useState('')
  const { offerList, callSelectedOffer = () => {} } = props
  const selectedRoom = (event: any) => {
    const offerId = event.target.value
    setSelectedOffer(offerId)
    callSelectedOffer(offerId)
  }
  useEffect(() => {
    setSelectedOffer(offerList[0]?.ratePlanId)
    callSelectedOffer(offerList[0]?.ratePlanId)
  }, [])

  return (
    <RadioGroup value={selectedOffer} onChange={selectedRoom}>
      {offerList?.map((item: any, idx: number) => (
        <div className={styled.packageOffer} key={idx}>
          <div className={styled.title}>
            <FormControlLabel
              value={item.ratePlanId}
              control={<Radio />}
              label='Giá công bố tốt nhất'
            />
          </div>
          <Row>
            <Col md={7}>
              <div className={styled.services}>
                {/* <ul>
                  {/* <li>
                    <span>
                      <RestaurantOutlinedIcon />
                    </span>
                    
                  </li>
                  <li>
                    <span>
                      <RedeemOutlinedIcon />
                    </span>
                    {
                      'Giảm 15% dịch vụ ăn uống tại khách sạn (không gồm đồ uống có cồn)'
                    }
                  </li>
                  <li>
                    <span>
                      <AirportShuttleOutlinedIcon />
                    </span>
                    {'Xe bus đón tiễn'}
                  </li>
                  <li>
                    <span>
                      <RedeemOutlinedIcon />
                    </span>
                    {
                      'Tặng 01 Voucher Tour Khám phá trị giá 500.000 VND/Phòng/đêm, lưu trú trước 31/08/2023'
                    }
                  </li>
                  <li>
                    <span>
                      <EventBusyOutlinedIcon />
                    </span>
                    {'Không hoàn/ hủy'}
                  </li> 
                  
                </ul> */}
                {/* <div>{item?.description}</div> */}
                {item?.shortDescription
                  ?.split('\n')
                  ?.map((line: any, index: any) => (
                    <Typography key={index}>
                      <ChevronRightOutlinedIcon />
                      {line}
                    </Typography>
                  ))}
                {/* <div style={{ width: 450 }}>
                  <Typography
                    sx={{
                      textWrap: 'wrap',
                      // scrollableText: {
                      //   maxHeight: '200px',
                      //   overflowX: 'auto',
                      // },
                      // wrappedText: {
                      //   overflowWrap: 'break-word',
                      //   wordWrap: 'break-word',
                      //   hyphens: 'auto',
                      // },
                    }}
                    variant='body1'
                    component='pre'
                  >
                    {item?.shortDescription}
                  </Typography>
                </div> */}
              </div>
            </Col>
            <Col md={5}>
              <div className={styled.prices}>
                <div>
                  Giá công bố{' '}
                  <span>{currencyFormat(item.totalAmount, 'vi')} vnđ</span>
                </div>
                <div>
                  <p>
                    <InfoOutlinedIcon /> Giá thành viên
                    <br />
                    <b>{currencyFormat(item.memberRate, 'vi')} vnđ</b>
                    <br />
                    <p>/đêm</p>
                  </p>
                </div>
                <div>
                  <BaseButton>
                    <InfoOutlinedIcon />
                    Điều kiện gói
                  </BaseButton>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </RadioGroup>
  )
}
export default PackageOffer
