import { TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { StyledTooltip } from './CustomTooltip'

export const BaseInput = (props: any) => {
  const {
    value = undefined,
    name,
    label,
    rules,
    helperText,
    inputProps,
    InputProps,
    defaultValue,
    showErrorMessage = true,
    isShowMessage = true,
    required,
    icon,
    sx,
    ...other
  } = props
  const { control } = useFormContext()
  const renderInput = ({ field, error }: any) => (
    <TextField
      {...field}
      value={
        value
          ? value
          : typeof field.value === 'number' && field.value === 0
          ? 0
          : field.value
          ? field.value
          : ''
      }
      label={
        label &&
        (required ? (
          <>
            {label}
            <span className='text-red ml-3'>* </span>
          </>
        ) : (
          <>{label}</>
        ))
      }
      error={!!error}
      // helperText={error ? error.message : helperText}
      sx={sx}
      fullWidth
      InputProps={{
        ...InputProps,
        inputProps: { ...inputProps },
        startAdornment: icon,
      }}
      {...other}
    />
  )
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) =>
        showErrorMessage ? (
          <StyledTooltip
            arrow
            placement='top'
            title={(isShowMessage && error?.message) || ''}
            isError={isShowMessage ? Boolean(error?.message) : false}
          >
            {renderInput({ field, error })}
          </StyledTooltip>
        ) : (
          renderInput({ field, error })
        )
      }
      {...other}
    />
  )
}
