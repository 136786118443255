import { Button } from '@mui/material'
import React from 'react'

export const BaseButton = (props: any) => {
  const { type, onClick, children, variant, style, color, ...other } = props
  return (
    <Button
      type={type}
      onClick={onClick}
      variant={variant}
      style={style}
      color={color}
      {...other}
    >
      {children}
    </Button>
  )
}
