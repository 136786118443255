import { FC, useState } from 'react'
import { Carousel } from 'react-bootstrap'

interface LBCarouselProp {
  itemList?: any[]
}
const LBCarousel: FC<LBCarouselProp> = (props) => {
  const { itemList = [] } = props
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex)
  }

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {itemList?.map((item) => {
        return <Carousel.Item>{item}</Carousel.Item>
      })}
    </Carousel>
  )
}
export default LBCarousel
