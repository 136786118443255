import PhotoAlbum from 'react-photo-album'
import styled from '../styles/PhotoBook.module.scss'
import ImageViwer from '../../../components/ImageViewer'
import React, { useState } from 'react'
import { galeryPhoto } from '../../../data/media'
const PhotoBook = () => {
  const [show, setShow] = useState(false)
  const [selectedIdx, setSelectedIdx] = useState(-1)
  const onClick = (e: any) => {
    setSelectedIdx(e.index)
    setShow(true)
  }
  return (
    <div className={styled.photoBook}>
      <div className={styled.title}>
        <h3>Photo Galery</h3>
      </div>
      <PhotoAlbum
        layout='masonry'
        spacing={3}
        onClick={onClick}
        photos={galeryPhoto}
      />
      <ImageViwer
        photoIdx={selectedIdx}
        photos={galeryPhoto}
        show={show}
        setShow={setShow}
      />
    </div>
  )
}
export default PhotoBook
