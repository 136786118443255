import React from 'react'
import { FormProvider as Form, UseFormReturn } from 'react-hook-form'

type Props = {
  children: React.ReactNode
  method: UseFormReturn<any>
  onSubmit?: VoidFunction
}
export const FormProvider = ({ children, method, onSubmit }: Props) => {
  return (
    <Form {...method}>
      <form onSubmit={onSubmit} noValidate>
        {children}
      </form>
    </Form>
  )
}
