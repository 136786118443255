import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import LanguagePicker from '../components/ui/LanguagePicker'
import { useTranslation } from 'react-i18next'
import '../assets/style/LbNavbar.scss'
import Login from '../components/ui/Login'
import { PagePath } from '../data/constants'

export default function LBNavbar({ showNavbar, mouseIn }: {
  showNavbar?: boolean
  mouseIn?: boolean
  }) {
  const { t } = useTranslation()
  return (
    <div className={`lb-navbar`}>
      <Navbar bg='light' expand='lg'>
        <Container fluid>
          <Navbar.Brand href='/'>
            <div className='image'>
              <img
                src={require('../assets/images/icons/home-icon.png')}
                alt='home'
              />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls={showNavbar ? 'navbarScroll' : 'hide-navbar'}
          />
          <Navbar.Collapse id='navbarScroll'>
            <Nav className={`me-auto my-2 my-lg-0 ${mouseIn ? 'highlight' : ''}`}>
              <div className='nav-home'>
                <Nav.Link href={PagePath.Home}>
                  <h4>Home</h4>
                </Nav.Link>
              </div>
              <Nav.Link href={PagePath.StoriesPage}>
                <h4>{t('content.navbar.overview')}</h4>
              </Nav.Link>
              <Nav.Link href='/accommodations'>
                <h4>Bungalow</h4>
              </Nav.Link>
              <Nav.Link href={PagePath.Tours}>
                <h4>{t('content.navbar.tour')}</h4>
              </Nav.Link>
              <Nav.Link href={PagePath.SpecialOffers}>
                <h4>{t('content.navbar.specialOffers')}</h4>
              </Nav.Link>
              <Nav.Link href={PagePath.Booking}>
                <h4>{t('content.common.booking')}</h4>
              </Nav.Link>
              <Nav.Link href={PagePath.Services}>
                <h4>{`Dịch vụ`}</h4>
              </Nav.Link>
              {/* <Nav.Link href='/contactUs'>
                <h4>{t('content.common.contactUs')}</h4>
              </Nav.Link> */}
              <Nav.Link href={PagePath.Feedback}>
                <h4>{`Nhận xét`}</h4>
              </Nav.Link>
            </Nav>
            {showNavbar ? <Login /> : <></>}
            <LanguagePicker />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}
