import { Row, Col } from "react-bootstrap";
import styled from "../assets/style/TourDetail.module.scss";
const TourDetail = () => {
  return (
    <div className={styled.tourDetail}>
      <Row>
        <Col md={8}>
          <div className={styled.article}>
            <h4>Tour title</h4>
            <div>Tour photo</div>
            <div>
              <p>địa điểm</p>
              <p>thời gian tour</p>
              <p>phương tiện</p>
              <p>mã tour</p>
            </div>
            <div>
              Chi tiet
              <article>
                Thái Lan – Vùng đất diệu kỳ Du lịch Thái Lan, đất nước thu hút
                du khách khắp thế giới không chỉ bởi sự hiếu khách, những nụ
                cười thân thiện; mà còn rất nhiều phong cảnh thiên nhiên tuyệt
                đẹp, hoang sơ. Du khách không thể bỏ qua thành phố náo nhiệt,
                trung tâm mua sắm sầm uất Bangkok hay Thành phố Pattaya với cách
                hoạt động vui chơi giải trí như chèo thuyền, bơi lặn, xem box
                Thái. Hãy cùng iVIVU khám phá vùng đất tuyệt vời này! Những trải
                nghiệm thú vị trong chương trình - Tham quan Đảo san hô Koh Larn
                bằng tàu cao tốc. - Muang Boran và trải nghiệm Massage Thái Cổ
                Truyền thư giãn. - Thưởng thức Buffet quốc tế với hàng chục món
                ăn đặc sắc – Ngắm toàn cảnh thành phố Bangkok từ tòa nhà cao
                nhất Thái Lan Bayoke Sky. - Viếng Tứ Diện Phật Miếu, Chùa Vàng
                Wat Trimit, Chùa Phật lớn Wat Phra Yai… - Công ty tặng Quý khách
                1 suất trải nghiệm Massage Thái Cổ Truyền thư giãn và thưởng
                thức café dát vàng theo phong cách quý tộc. Bạn có sẵn sàng -
                Vaccine Certificate (Chứng chỉ vắc xin) bản tiếng anh: Xác nhận
                tiêm ít nhất 2 mũi vaccine có mã QR code/ hoặc xác nhận F0 khỏi
                bệnh (tiếng anh). Thông tin trên chứng chỉ phải trùng khớp với
                thông tin trên hộ chiếu. - Trẻ em: bé trên 12 tuổi bắt buộc phải
                có chứng nhận tiêm vắc xin đủ 2 mũi. Trường hợp bé dưới 12 tuổi
                chưa đủ tuổi tiêm đi kèm với bố mẹ đã tiêm không cần test.
                Chương trình tour NGÀY 1: SÀI GÒN – BANGKOK - PATTAYA ( ĂN TRƯA
                COUPON, TỐI) Trưởng đoàn đón quý khách tại sân bay Tân Sơn Nhất,
                làm thủ tục chuyến bay đi Bangkok. Đến sân bay Suvarnabhum, quý
                khách làm thủ tục nhập cảnh Thái Lan. HDV địa phương đón đoàn
                dùng bữa trưa coupon trong sân bay và bắt đầu khám phá “Xứ Sở
                Chùa Vàng linh thiêng”. HDV đưa đoàn về thành phố biển Pattaya,
                tham quan: - Khao Phra Tamnak đây là khu vực cao nhất Pattaya,
                nơi đây du khách có thể ngắm toàn cảnh thành phố biển từ trên
                cao, bãi biển cong rất đẹp.
              </article>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className={styled.sideBooking}>
            <div className={styled.sticky}>
              <div>
                Lịch khởi hành ngày
                <button>Tư vấn</button>
                <button>Đặt chỗ</button>
              </div>

              <div>
                Bảo hiểm Bữa ăn Hướng dẫn viên Khách sạn 3-4* Vé máy bay Vé tham
                quan Xe đưa đón
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TourDetail;
