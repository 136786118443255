import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BaseDatePicker } from '../components/form/BaseDatePicker'
import { BaseInput } from '../components/form/BaseInput'
import CountrySelect, {
  CountryType,
  countries,
} from '../components/form/CountrySelect'
import { FormProvider } from '../components/form/FormProvider'

import { Backdrop, CircularProgress, FormLabel } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import moment from 'moment'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { BaseButton } from '../components/form/BaseButton'
import { BaseRadioGroup } from '../components/form/BaseRadioGroup'
import {
  EApi,
  EServerity,
  EStatusCode,
  MESSAGE,
  OPTION_FEEDBACK_3,
  OPTION_FEEDBACK_4,
  OPTION_FEEDBACK_5,
  OPTION_FEEDBACK_5_1,
  PagePath,
} from '../data/constants'
import { createData } from '../services/dataProvider'
import { converStringToDate } from '../services/dateUtil'
import { validateEmail, validatePhone } from '../utils/ultils'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
const Feedback = () => {
  const methods = useForm<any>({})

  const navigate = useNavigate()
  const { handleSubmit, getValues, setValue, watch, reset } = methods
  // loading
  const [openLoading, setOpenLoading] = useState(false)
  //snackbar
  const [openMessage, setOpenMessage] = useState(false)
  const [meesageError, setMessageError] = useState('')
  const [serverity, setServerity] = useState('')
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenMessage(false)
  }
  //end snackbar

  //local storage
  const dataUser =
    localStorage.getItem('userInfo') &&
    JSON.parse(localStorage.getItem('userInfo') || '')

  //function
  const formatDataUser = (data: any) => {
    return {
      ...data,
      fullName: data?.userName,
      birthday: converStringToDate(data?.dateOfBirth),
      nationality: countries.find(
        (item: CountryType) => item?.code === data?.nationality
      ),
    }
  }
  useEffect(() => {
    dataUser && reset(formatDataUser(dataUser))
  }, [])

  const formatData = (data: any) => {
    return {
      birthday: moment(data?.birthday).format('YYYY-MM-DD'),
      startDate: moment(data?.startDate).format('YYYY-MM-DD'),
      endDate: moment(data?.endDate).format('YYYY-MM-DD'),
      email: data?.email,
      fullName: data?.fullName,
      lengthOfStay: data?.lengthOfStay,
      nationality: data.nationality?.code,
      phoneNumber: data?.phoneNumber,
      specialOccasions: data?.specialOccasions,
      feedback: {
        friendlinessStaff: data?.friendlinessStaff,
        joinActivity: data?.joinActivity,
        overviewExperience: data?.overviewExperience,
        otherActivity: data?.otherActivity,
        qualityOfEntertainment: data?.qualityOfEntertainment,
        qualityOfFood: data?.qualityOfFood,
        qualityOfRoom: data?.qualityOfRoom,
        qualityOfSpa: data?.qualityOfSpa,
        recommendToOther: data?.recommendToOther,
        roomPreferenceMet: data?.roomPreferenceMet,
        satisfiedVariety: data?.satisfiedVariety,
        staffResponsive: data?.staffResponsive,
        suggestionForImproving: data?.suggestionForImproving,
        otherFeedbackRoom: data?.otherFeedbackRoom,
        otherFeedbackFood: data?.otherFeedbackFood,
        otherFeedbackSpa: data?.otherFeedbackSpa,
        otherFeedbackRoomPreferenceMet: data?.otherFeedbackRoomPreferenceMet,
      },
    }
  }

  const onSubmit = async (data: any) => {
    setOpenLoading(true)
    await createData(EApi.feedback, formatData(data), (res) => {
      if (res.data?.statusCode === EStatusCode.SUCCESS) {
        setMessageError('Lưu đánh giá thành công.')
        setServerity(EServerity.SUCCESS)
        setOpenMessage(true)
        setTimeout(() => {
          navigate(PagePath.Home)
        }, 1000)
      } else {
        setMessageError(res.data?.errorMessage)
        setServerity(EServerity.ERROR)
        setOpenMessage(true)
      }
      setOpenLoading(false)
    })
  }
  return (
    <>
      <FormProvider method={methods} onSubmit={handleSubmit(onSubmit)}>
        <div>Thông tin khách hàng</div>
        <Row>
          <Col>
            <BaseInput
              name='fullName'
              sx={{ marginTop: '20px' }}
              required
              label='Tên đầy đủ'
              rules={{
                validate: (value: any) => {
                  if (!value) return MESSAGE.REQUIRED
                },
              }}
            />
          </Col>
          <Col>
            <BaseInput
              name='email'
              required
              label='Email'
              sx={{ marginTop: '20px' }}
              rules={{
                validate: (value: any) => {
                  if (!value) return MESSAGE.REQUIRED
                  if (!validateEmail(value)) return MESSAGE.EMAIL
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BaseInput
              name='phoneNumber'
              label='Số điện thoại liên hệ'
              inputProps={{ type: 'number' }}
              required
              sx={{ marginTop: '20px' }}
              rules={{
                validate: (value: any) => {
                  if (!value) return MESSAGE.REQUIRED
                  if (!validatePhone(value)) return MESSAGE.FORMAT_PHONE
                  if (value.length < 9) return MESSAGE.LENGHT_FORMAT_PHONE
                },
              }}
            />
          </Col>
          <Col>
            <CountrySelect
              name='nationality'
              sx={{ marginTop: '20px' }}
              required
              label='Quốc tịch'
              rules={{
                validate: (value: any) => {
                  if (!value) return MESSAGE.REQUIRED
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BaseDatePicker
              name='birthday'
              required
              label='Ngày sinh'
              rules={{
                validate: (value: any) => {
                  if (!moment(value).isValid() || !value)
                    return MESSAGE.REQUIRED
                },
              }}
            />
          </Col>
          <Col>
            <BaseInput
              name='specialOccasions'
              label='Dịp đặc biệt'
              sx={{ marginTop: '20px' }}
            />
          </Col>
        </Row>
        <div>Chi tiết đặt phòng và lưu trú</div>
        <Row>
          <Col>
            <BaseDatePicker
              name='startDate'
              label='Ngày checkin'
              required
              rules={{
                validate: (value: any) => {
                  if (!moment(value).isValid() || !value)
                    return MESSAGE.REQUIRED
                },
              }}
            />
          </Col>
          <Col>
            <BaseDatePicker
              name='endDate'
              label='Ngày checkout'
              required
              minDate={
                watch('startDate') && moment(watch('startDate')).add('1', 'day')
              }
              onChange={(value: any) => {
                getValues('startDate') &&
                  setValue(
                    'lengthOfStay',
                    moment(value).diff(getValues('startDate'), 'days')
                  )
                setValue('endDate', moment(value))
              }}
              rules={{
                validate: (value: any) => {
                  if (!moment(value).isValid() || !value)
                    return MESSAGE.REQUIRED
                },
              }}
            />
          </Col>
          <Col>
            <BaseInput
              name='lengthOfStay'
              type='number'
              label='Thời gian lưu trú'
              sx={{ width: '177px', marginTop: '10px' }}
              disabled
            />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>

        <div>Phản hồi và khảo sát</div>

        <div>1. Trải nghiệm tổng thể</div>
        <Row>
          <Col>
            <BaseRadioGroup
              name='overviewExperience'
              label='Đánh giá trải nghiệm chung của bạn tại khu nghỉ dưỡng:'
              options={OPTION_FEEDBACK_4}
              defaultValue={'excellent'}
            />
          </Col>
          <Col>
            <BaseRadioGroup
              name='recommendToOther'
              label='Khả năng bạn giới thiệu resort cho người khác như thế nào?'
              options={OPTION_FEEDBACK_5_1}
              defaultValue={'veryLikely'}
            />
          </Col>
        </Row>
        <div>2. Các dịch vụ và cơ sở vật chất cụ thể:</div>
        <Row>
          <Col>
            <BaseRadioGroup
              name='qualityOfRoom'
              label='Bạn đánh giá chất lượng phòng như thế nào? Nếu không, xin vui
          lòng ghi rõ.'
              options={OPTION_FEEDBACK_4}
              defaultValue={'excellent'}
            />
            {watch('qualityOfRoom') === 'notGood' ? (
              <BaseInput
                name='otherFeedbackRoom'
                sx={{ marginTop: '20px' }}
                rules={{
                  validate: (value: any) => {
                    if (watch('qualityOfRoom') === 'notGood' && !value)
                      return MESSAGE.REQUIRED
                  },
                }}
              />
            ) : (
              <></>
            )}
          </Col>
          <Col>
            <BaseRadioGroup
              name='roomPreferenceMet'
              label='Sở thích phòng của bạn có được đáp ứng không? Nếu không, xin vui
            lòng ghi rõ.'
              options={OPTION_FEEDBACK_3}
              defaultValue={'yes'}
            />
            {watch('roomPreferenceMet') === 'no' ? (
              <BaseInput
                name='otherFeedbackRoomPreferenceMet'
                sx={{ marginTop: '20px' }}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <BaseRadioGroup
              name='qualityOfFood'
              label='Đánh giá chất lượng ăn uống. Nếu không, xin vui
          lòng ghi rõ.'
              options={OPTION_FEEDBACK_4}
              defaultValue={'excellent'}
            />
            {watch('qualityOfFood') === 'notGood' ? (
              <BaseInput
                name='otherFeedbackFood'
                sx={{ marginTop: '20px' }}
                rules={{
                  validate: (value: any) => {
                    if (watch('qualityOfFood') === 'notGood' && !value)
                      return MESSAGE.REQUIRED
                  },
                }}
              />
            ) : (
              <></>
            )}
          </Col>
          <Col>
            <BaseRadioGroup
              name='satisfiedVariety'
              label='Vui lòng cho biết mức độ hài lòng của bạn với các lựa chọn ăn uống đa
          dạng?'
              options={OPTION_FEEDBACK_5}
              defaultValue={'verySatisfied'}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            {' '}
            <BaseRadioGroup
              name='qualityOfSpa'
              label='Đánh giá chất lượng dịch vụ spa và chăm sóc sức khỏe. Nếu không, xin vui
          lòng ghi rõ.'
              options={OPTION_FEEDBACK_4}
              defaultValue={'excellent'}
            />
            {watch('qualityOfSpa') === 'notGood' ? (
              <BaseInput
                name='otherFeedbackSpa'
                sx={{ marginTop: '20px' }}
                rules={{
                  validate: (value: any) => {
                    if (watch('qualityOfSpa') === 'notGood' && !value)
                      return MESSAGE.REQUIRED
                  },
                }}
              />
            ) : (
              <></>
            )}
          </Col>
          <Col></Col>
        </Row>
        <div>3. Các hoạt động và giải trí:</div>
        <Row>
          <Col>
            <BaseRadioGroup
              name='qualityOfEntertainment'
              label='Đánh giá mức độ sẵn có và chất lượng của các phương tiện giải trí'
              options={OPTION_FEEDBACK_5}
              defaultValue={'verySatisfied'}
            />
          </Col>
          <Col>
            <BaseRadioGroup
              name='joinActivity'
              label='Bạn đã tham gia vào bất kỳ hoạt động khu nghỉ mát? Nếu có, xin vui
          lòng ghi rõ.'
              options={OPTION_FEEDBACK_3}
              defaultValue={'yes'}
            />
            {watch('joinActivity') === 'yes' ? (
              <BaseInput name='otherActivity' />
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <div>4. Nhân viên và Dịch vụ:</div>
        <Row>
          <Col>
            {' '}
            <BaseRadioGroup
              name='friendlinessStaff'
              label='Bạn đánh giá thế nào về sự thân thiện và chuyên nghiệp của nhân viên
          resort?'
              options={OPTION_FEEDBACK_4}
              defaultValue={'excellent'}
            />
          </Col>
          <Col>
            <BaseRadioGroup
              name='staffResponsive'
              label='Nhân viên có đáp ứng nhu cầu và yêu cầu của bạn không?'
              options={OPTION_FEEDBACK_3}
              defaultValue={'yes'}
            />
          </Col>
        </Row>
        <div>5. Đề xuất và cải tiến:</div>
        <Row>
          <Col>
            <FormLabel id='overallExperience'>
              Bạn có bất cứ đề nghị để cải thiện dịch vụ khu nghỉ mát?
            </FormLabel>
            <BaseInput
              name='suggestionForImproving'
              sx={{ marginTop: '20px' }}
            />
          </Col>
        </Row>

        <BaseButton type='submit'>Lưu đánh giá</BaseButton>
      </FormProvider>
      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={serverity as any}
          sx={{ width: '100%' }}
        >
          {meesageError}
        </Alert>
      </Snackbar>
      <Backdrop open={openLoading} sx={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress size={100} color='inherit' />
      </Backdrop>
    </>
  )
}

export default Feedback
