import TextareaAutosize from '@mui/base/TextareaAutosize'
import { Controller, useFormContext } from 'react-hook-form'

export const BaseTextArea = (props: any) => {
  const { name, label, rules, helperText, required, ...other } = props
  const { control } = useFormContext()
  const ariaLabel = label ? `${name}-${label}` : ''
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <TextareaAutosize
            {...field}
            value={field.value || ''}
            aria-labelledby={ariaLabel}
            label={
              label &&
              (required ? (
                <>
                  {label}
                  <span className='text-red ml-3'>* </span>
                </>
              ) : (
                <>{label}</>
              ))
            }
            error={!!error}
            helperText={error ? error?.message : helperText}
            {...other}
          />
        )}
        {...other}
      />
    </>
  )
}
