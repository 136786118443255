import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const useMouseMove = (onMouseMove: any) => {
  useEffect(() => {
      document.addEventListener("mousemove", onMouseMove)

      return () => {
          document.removeEventListener("mousemove", onMouseMove)
      }
  }, [onMouseMove])
}

export { useMouseMove, useWindowDimensions }