import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PagePath } from '../../../data/constants'
import '../styles/PromotionDetail.scss'

const PromotionDetail = () => {
  const navigate = useNavigate()
  const gotoBooking = () => {
    navigate(PagePath.Booking)
  }
  const uudaihe = (
    <div className="promotion-detail">
      <h4>Ưu đãi hè</h4>
      <>
        <h4>
          3 chương trình ưu đãi cực lớn trong tháng 6/2023 không thể bỏ lỡ{' '}
        </h4>
        <div>
          <h5>1. Giảm ngay 20% khi đặt phòng 2 đêm vào ngày trong tuần</h5>
          <p>
            Giảm ngay 20% khi đặt phòng 2 đêm vào ngày trong tuần nếu bạn đặt
            phòng trong khoảng thời gian từ thứ Hai đến thứ Năm và lưu trú ít
            nhất 2 đêm, bạn sẽ nhận được giảm giá 20% trên tổng số tiền phòng.
          </p>
          <div className="image">
            <img
              src="https://cucphuongresort.vn/wp-content/uploads/2023/06/Copy-of-CPR_Facebook-Ads-June.png"
              alt="1"
            />
          </div>
        </div>
        <div>
          <h5>
            2. Giảm ngay 15% khi đặt phòng 1 đêm trước 7 ngày cho các ngày trong
            tuần
          </h5>
          <p>
            Giảm ngay 15% khi đặt phòng 1 đêm trước 7 ngày cho các ngày trong
            tuần. Cụ thể, nếu bạn đặt phòng 1 đêm và thực hiện đặt trước ít nhất
            7 ngày trước ngày nhận phòng, trong khoảng thời gian từ thứ Hai đến
            thứ Năm, bạn sẽ nhận được giảm giá 15% trên tổng số tiền phòng.
          </p>
          <div className="image">
            <img
              src="https://cucphuongresort.vn/wp-content/uploads/2023/06/Copy-of-CPR_Facebook-Ads-June-1.png"
              alt="2"
            />
          </div>
        </div>
        <div>
          <h5>
            3. Giảm ngay 25% khi đặt phòng 3 đêm trước 7 ngày cho các ngày trong
            tuần.
          </h5>
          <p>
            Giảm ngay 25% khi đặt phòng 3 đêm trước 7 ngày cho các ngày trong
            tuần. Khi lưu trú lâu hơn, từ 3 đêm trở lên và đặt trước ít nhất 7
            ngày trước ngày nhận phòng cho kỳ nghỉ trong khoảng thời gian từ thứ
            Hai đến thứ Năm, bạn sẽ nhận được giảm giá 25% trên tổng số tiền
            phòng.
            <div className="image">
              <img
                src="https://cucphuongresort.vn/wp-content/uploads/2023/06/Copy-of-CPR_Facebook-Ads-June-2.png"
                alt="3"
              />
            </div>
          </p>
        </div>
      </>
      <div className="book-now">
        <button onClick={gotoBooking}>
          <h4>Đặt ngay</h4>
        </button>
      </div>
    </div>
  )

  return <>{uudaihe}</>
}

export default PromotionDetail
