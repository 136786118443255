import PropTypes from 'prop-types'
import '../../assets/style/UserMenu.scss'
import { Nav, NavDropdown } from 'react-bootstrap'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined'
import { useEffect, useState } from 'react'
import useAppStore from '../../store/appStore'
const Login = () => {
  const [isLogged, setIsLogged] = useState(false)
  const [userToken, setUserToken] = useState('')
  const { currentPage } = useAppStore((state) => state)
  useEffect(() => {
    if (currentPage?.includes('logout')) {
      localStorage.removeItem('userInfo')
      setUserToken('')
    }
    const dataUser =
      localStorage.getItem('userInfo') &&
      JSON.parse(localStorage.getItem('userInfo') || '')

    if (dataUser?.token) {
      setIsLogged(true)
      setUserToken(dataUser?.token || '')
    }
  }, [])
  return (
    <div className='user-menu'>
      {!isLogged ? (
        <>
          <Nav.Link href='/login'>
            <h6>Đăng nhập</h6>
          </Nav.Link>
        </>
      ) : (
        <NavDropdown
          id='nav-dropdown-dark-example'
          title={<EmojiEmotionsOutlinedIcon />}
          menuVariant='light'
        >
          <NavDropdown.Item href={`/user/${userToken}`}>
            Tài khoản
          </NavDropdown.Item>
          {/* <NavDropdown.Item href='/actingService'>Kỳ nghỉ</NavDropdown.Item>
          <NavDropdown.Item href='/bookingHistory'>Quản lý đặt chỗ</NavDropdown.Item> */}
          <NavDropdown.Divider />
          <NavDropdown.Item href='/logout'>Đăng xuất</NavDropdown.Item>
        </NavDropdown>
      )}
    </div>
  )
}
export default Login

Login.propTypes = {
  name: PropTypes.string,
}
