import styled from '../assets/style/Home.module.scss'
import Introduction from '../features/home/components/Introduction'
import Promotions from '../features/home/components/Promotions'
import BookingComponent from '../features/home/components/BookingComponent'
import SpaService from '../features/home/components/SpaService'
import PostScript from '../features/home/components/PostScript'
import HomeAccommodations from '../features/home/components/HomeAccommodations'
import { EApi, EServerity, EStatusCode, PagePath } from '../data/constants'
import { useNavigate } from 'react-router-dom'
import useBookingInfoStore from '../store/bookingInfoStore'
import { search } from '../services/dataProvider'
import { getYYYYMMDD } from '../services/dateUtil'
import useRoomStore from '../store/roomStore'
import React, { useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Backdrop, CircularProgress } from '@mui/material'
import PhotoBook from '../features/home/components/PhotoBook'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Home = () => {
  const navigate = useNavigate()

  // loading
  const [openLoading, setOpenLoading] = useState(false)
  const { setBookingInfo } = useBookingInfoStore((state) => state)
  const { setListRoom } = useRoomStore((state) => state)

  // const formatDataRoom = (data: any) =>
  //   data.map((item: any) => {
  //     console.log({ ...item, images: data.images?.split(';') })
  //   })

  //snackbar
  const [openMessage, setOpenMessage] = useState(false)
  const [meesageError, setMessageError] = useState('')
  const [serverity, setServerity] = useState('')
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenMessage(false)
  }
  //end snackbar

  const searchRoom = async (data: any) => {
    setOpenLoading(true)
    let roomOccupancy = ''
    for (let index = 0; index < data?.numOfRoom; index++) {
      roomOccupancy += `${data?.occupancyAdult}_${data?.occupancyChild},`
    }
    await search(
      EApi.searchRoom,
      {
        numOfRoom: data?.numOfRoom,
        endDate: getYYYYMMDD(data.endDate),
        startDate: getYYYYMMDD(data.startDate),
        roomOccupancy,
      },
      (response: any) => {
        if (response.data?.statusCode === EStatusCode.SUCCESS) {
          setListRoom(response?.data.data)
          setBookingInfo(data)
          setMessageError('Tìm kiếm thành công.')
          setServerity(EServerity.SUCCESS)
          setOpenMessage(true)
          setTimeout(() => {
            navigate(PagePath.Booking)
          }, 1000)
        } else if (response.data?.statusCode === EStatusCode.INTERNAL_SERVER_ERROR) {
          setListRoom([])
          setMessageError('Tìm kiếm thất bại.')
          setServerity(EServerity.ERROR)
          setOpenMessage(true)
        } else {
          setListRoom([])
          setMessageError('Không tìm thấy phòng.')
          setServerity(EServerity.ERROR)
          setOpenMessage(true)
        }
      }
    )
    setOpenLoading(false)
  }

  return (
    <div className={styled.homepage}>
      <div className={styled.booking}>
        <BookingComponent callOnSubmit={searchRoom} />
      </div>
      <div className={styled.section}>
        <Introduction />
      </div>
      <div className={styled.section}>
        <HomeAccommodations />
      </div>
      <div className={styled.section}>
        <SpaService />
      </div>
      <div className={styled.section}>
        <Promotions />
      </div>
      <div className={styled.section}>
        <PostScript />
      </div>
      <div className={styled.section}>
        <PhotoBook />
      </div>
      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={serverity as any}
          sx={{ width: '100%' }}
        >
          {meesageError}
        </Alert>
      </Snackbar>
      <Backdrop open={openLoading} sx={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress size={100} color='inherit' />
      </Backdrop>
    </div>
  )
}
export default Home
