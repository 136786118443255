import { ImageObject } from './type'

const headerMedia = {
  // video: 'https://youtu.be/9jFNxBkLAbM',
  video: 'https://statics.vinpearl.com/Video banner_1672906744.mp4',
  test: 'abc',
}

const galeryPhoto = [
  {
    src: 'https://ik.imagekit.io/tvlk/blog/2022/01/dia-diem-du-lich-sapa-27.jpg',
    width: 800,
    height: 600,
  },
  {
    src: 'https://reviewvilla.vn/wp-content/uploads/2022/06/dia-diem-du-lich-sapa-1.png',
    width: 1600,
    height: 900,
  },
  {
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2bvvcCI4HqU5tzmnkWmGW6k6OiNMwc253sI7L0Nnum4EsRmOR6O-X8QOkjIwDDJrblSY&usqp=CAU',
    width: 1600,
    height: 900,
  },
  {
    src: 'https://phongnhaexplorer.com/wp-content/uploads/2021/08/kinh-nghiem-du-lich-sapa-a.jpg',
    width: 800,
    height: 600,
  },
  {
    src: 'https://hitour.vn/storage/images/upload/tour-du-lich-sapa-3-ngay-2-dem-750.webp',
    width: 1600,
    height: 900,
  },
  {
    src: 'https://static.mservice.io/blogscontents/momo-upload-api-200623154302-637285237826541685.jpg',
    width: 1600,
    height: 900,
  },
  {
    src: 'https://divui.com/blog/wp-content/uploads/2017/11/kinh-nghiem-du-lich-sapa-1.jpg',
    width: 1600,
    height: 900,
  },
  {
    src: 'https://www.guidevietnam.org/wp-content/uploads/2020/07/Walking-through-the-villages-in-Sapa.jpg',
    width: 800,
    height: 600,
  },
  {
    src: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1a/d9/69/91/sapa-jade-hill-resort.jpg?w=700&h=-1&s=1',
    width: 1600,
    height: 900,
  },
  {
    src: 'https://sayhi.vn/blog/wp-content/uploads/2020/01/resort-sapa-17.jpg',
    width: 1600,
    height: 900,
  },
  {
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCtYjSoEaPGN5tNnTMI6Lv11DhEKofImn-pQ&usqp=CAU',
    width: 800,
    height: 600,
  },
  {
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRH6oufYtWnZNuhcRhuplc_fyunkdoC9YLwIWG6Stvs2TFtGl4t_G1zL5wMNJFvt37LnM4&usqp=CAU',
    width: 1600,
    height: 900,
  },
  {
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC-AFo1eYjDlT65cR39tPu8CuwhNn8OpB0Wg&usqp=CAU',
    width: 1600,
    height: 900,
  },
  {
    src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtpQw83ys29EpStts9NkUiVADoH6knnwZ1C844OEa1xWb1Jd1IuEbf_iDOq2Mhpwbm0QU&usqp=CAU',
    width: 800,
    height: 600,
  },
]
const imageList: ImageObject[] = [
  {
    path: 'https://lh3.googleusercontent.com/p/AF1QipP1wCFavpoBhq-KktXgaQ-4A22nYR6U9QVBFoSl=s1360-w1360-h1020',
    title: 'Home 1',
  },
  {
    path: 'https://lh3.googleusercontent.com/p/AF1QipPxt-Hb5WXXlqBJBO_0xh5g4w_UX41Dx7VKF-kR=s1360-w1360-h1020',
    title: 'Home 1',
  },
  {
    path: 'https://www.hpdconsult.com/wp-content/uploads/2019/10/HPD-Construction-and-Interiors.jpg',
    title: 'Home 1',
  },
  {
    path: 'https://lh3.googleusercontent.com/p/AF1QipPeGlPamqvkfUgtIuGce2pZ6BqSLRLj_xQGJC3P=s1360-w1360-h1020',
    title: 'Home 1',
  },
  {
    path: 'https://www.saykooliew.com/images/slideshow-home/img-slideshow-2021-07.jpg',
    title: 'Home 1',
  },
]

const introductionImg = [
  { title: 'Tổ chức sự kiện', url: 'https://i.ibb.co/tmS9ShG/event.jpg' },
  { title: 'Lửa trại', url: 'https://i.ibb.co/7bcZMrL/camping.jpg' },
  { title: 'Team Building', url: 'https://i.ibb.co/Ldx2JfV/teambuilding.jpg' },
  { title: 'Ẩm thực', url: 'https://i.ibb.co/DpHG1zc/cuisine.jpg' },
  { title: 'Xe đạp', url: 'https://i.ibb.co/Hr7qccT/biking.jpg' },
  { title: 'Xe máy', url: 'https://i.ibb.co/92sb8Z2/motor.jpg' },
  { title: 'BBQ', url: 'https://i.ibb.co/hgjq9C7/bbq.jpg' },
  { title: 'Spa', url: 'https://i.ibb.co/XZZzBTM/spa.jpg' },
]

// Login Page
const loginFormBackGround = [
  { url: 'https://i.ibb.co/jgsBf7C/login-background-2.jpg', alt: '1' },
  { url: 'https://i.ibb.co/SQCgx8K/login-background-1.jpg', alt: '2' },
  { url: 'https://i.ibb.co/VtctdJk/login-background-3.jpg', alt: '3' },
]

const roomList = [
  {
    name: 'Biệt thự 2 phòng ngủ',
    roomId: 'room1',
    images: [
      {
        url: 'https://booking-static.vinpearl.com/room_types/6033487957c647dd98605c922e08b646_3630-002.jpg',
      },
      {
        url: 'https://booking-static.vinpearl.com/room_types/af626b45e3064a5db66ed16005d6a71a_3630-035.jpg',
      },
      {
        url: 'https://booking-static.vinpearl.com/room_types/d76f7196be2e4dc48052b4216cf5d3b6_3630-024.jpg',
      },
    ],
    ratePlan: [
      {
        ratePlanId: '1',
        roomId: 'room1',
        restaurant: 'Bữa sáng',
        redeem1:
          'Giảm giá 20% dịch vụ Spa, 15% F&B (không bao gồm đồ uống có cồn)',
        redeem2:
          'Tặng 01 Voucher Tour Khám phá trị giá 500.000 VND/Phòng/đêm, lưu trú trước 31/08/2023',
        airportShuttle: 'Xe bus đón tiễn',
        eventBusy: 'Không hoàn/ hủy',
        openRate: '159000',
        memberRate: '200000',
      },
      {
        ratePlanId: '2',
        roomId: 'room1',
        restaurant: 'Bữa trưa',
        redeem1:
          'Giảm giá 20% dịch vụ Spa, 15% F&B (không bao gồm đồ uống có cồn)',
        redeem2:
          'Tặng 01 Voucher Tour Khám phá trị giá 500.000 VND/Phòng/đêm, lưu trú trước 31/08/2023',
        airportShuttle: 'Xe bus đón tiễn',
        eventBusy: 'Không hoàn/ hủy',
        openRate: '359000',
        memberRate: '400000',
      },
    ],
  },
  {
    name: 'Biệt thự 3 phòng ngủ',
    roomId: 'room2',
    images: [
      {
        url: 'https://booking-static.vinpearl.com/room_types/6033487957c647dd98605c922e08b646_3630-002.jpg',
      },
      {
        url: 'https://booking-static.vinpearl.com/room_types/af626b45e3064a5db66ed16005d6a71a_3630-035.jpg',
      },
      {
        url: 'https://booking-static.vinpearl.com/room_types/d76f7196be2e4dc48052b4216cf5d3b6_3630-024.jpg',
      },
    ],
    ratePlan: [
      {
        ratePlanId: '1',
        roomId: 'room2',
        restaurant: 'Bữa sáng',
        redeem1:
          'Giảm giá 20% dịch vụ Spa, 15% F&B (không bao gồm đồ uống có cồn)',
        redeem2:
          'Tặng 01 Voucher Tour Khám phá trị giá 500.000 VND/Phòng/đêm, lưu trú trước 31/08/2023',
        airportShuttle: 'Xe bus đón tiễn',
        eventBusy: 'Không hoàn/ hủy',
        openRate: '559000',
        memberRate: '600000',
      },

      {
        ratePlanId: '2',
        roomId: 'room2',
        restaurant: 'Bữa trưa',
        redeem1:
          'Giảm giá 20% dịch vụ Spa, 15% F&B (không bao gồm đồ uống có cồn)',
        redeem2:
          'Tặng 01 Voucher Tour Khám phá trị giá 500.000 VND/Phòng/đêm, lưu trú trước 31/08/2023',
        airportShuttle: 'Xe bus đón tiễn',
        eventBusy: 'Không hoàn/ hủy',
        openRate: '759000',
        memberRate: '800000',
      },
    ],
  },
  {
    name: 'Biệt thự 4 phòng ngủ',
    roomId: 'room3',
    images: [
      {
        url: 'https://booking-static.vinpearl.com/room_types/6033487957c647dd98605c922e08b646_3630-002.jpg',
      },
      {
        url: 'https://booking-static.vinpearl.com/room_types/af626b45e3064a5db66ed16005d6a71a_3630-035.jpg',
      },
      {
        url: 'https://booking-static.vinpearl.com/room_types/d76f7196be2e4dc48052b4216cf5d3b6_3630-024.jpg',
      },
    ],
    ratePlan: [
      {
        ratePlanId: '1',
        roomId: 'room3',
        restaurant: 'Bữa sáng',
        redeem1:
          'Giảm giá 20% dịch vụ Spa, 15% F&B (không bao gồm đồ uống có cồn)',
        redeem2:
          'Tặng 01 Voucher Tour Khám phá trị giá 500.000 VND/Phòng/đêm, lưu trú trước 31/08/2023',
        airportShuttle: 'Xe bus đón tiễn',
        eventBusy: 'Không hoàn/ hủy',
        openRate: '959000',
        memberRate: '1000000',
      },
      {
        ratePlanId: '2',
        roomId: 'room2',
        restaurant: 'Bữa trưa',
        redeem1:
          'Giảm giá 20% dịch vụ Spa, 15% F&B (không bao gồm đồ uống có cồn)',
        redeem2:
          'Tặng 01 Voucher Tour Khám phá trị giá 500.000 VND/Phòng/đêm, lưu trú trước 31/08/2023',
        airportShuttle: 'Xe bus đón tiễn',
        eventBusy: 'Không hoàn/ hủy',
        openRate: '659000',
        memberRate: '1100000',
      },
    ],
  },
]
const image = [
  {
    rawFile: {
      path: 'room_1.jpg',
    },
    src: 'blob:http://localhost:3006/39c1e01e-10f5-4aa0-8eb3-c6a4a622f6b6',
    title: 'room_1.jpg',
  },
  {
    rawFile: {
      path: 'room_2.jpg',
    },
    src: 'blob:http://localhost:3006/1adeab27-b12f-4ac0-a1aa-91f546d3d105',
    title: 'room_2.jpg',
  },
]

const roomLocationImage = [
  { url: 'https://i.ibb.co/s9m10Pj/sodo.jpg', alt: 'sodotongquan' },
  { url: 'https://i.ibb.co/VCbxh7x/sodo.jpg', alt: 'sodokhuvuc' },
  { url: 'https://i.ibb.co/f1869hs/phong1.png', alt: 'phong1' },
  { url: 'https://i.ibb.co/8jLs2KM/phong2.png', alt: 'phong2' },
  { url: 'https://i.ibb.co/Gt4p6BM/phong3.png', alt: 'phong3' },
  { url: 'https://i.ibb.co/M2d3Kd6/phong4.png', alt: 'phong4' },
  { url: 'https://i.ibb.co/dBDJDp1/phong5.png', alt: 'phong5' },
  { url: 'https://i.ibb.co/FwGNfj9/phong6.png', alt: 'phong6' },
  { url: 'https://i.ibb.co/WnyVqDz/phong7.png', alt: 'phong7' },
  { url: 'https://i.ibb.co/h1cHtp8/phong8.png', alt: 'phong8' },
  { url: 'https://i.ibb.co/hBQb7Pg/phong9.png', alt: 'phong9' },
  { url: 'https://i.ibb.co/C7MS9jh/phong10.png', alt: 'phong10' },
  { url: 'https://i.ibb.co/XzG1yRr/phong11.png', alt: 'phong11' },
  { url: 'https://i.ibb.co/gmY5sLb/phong12.png', alt: 'phong12' },
  { url: 'https://i.ibb.co/6mgLnh4/phong13.png', alt: 'phong13' },
  { url: 'https://i.ibb.co/X7MbGxR/phong14.png', alt: 'phong14' },
]

const bungalowHoaDao = [
  { url: 'https://phoenixmocchauresort.com/wp-content/uploads/2023/06/CVP07962-Large.jpeg', alt: 'sodotongquan' },
  { url: 'https://i.ibb.co/kBS3j9F/hoadao4.jpg', alt: 'sodotongquan' },
  { url: 'https://i.ibb.co/wLmZN6H/hoadao2.jpg', alt: 'sodokhuvuc' },
  { url: 'https://i.ibb.co/XkXN0P3/hoadao3.jpg', alt: 'phong1' },
  { url: 'https://i.ibb.co/kBS3j9F/hoadao4.jpg', alt: 'phong2' },
  { url: 'https://i.ibb.co/3yD2f72/hoadao5.jpg', alt: 'phong3' },
]

const bungalowHoaMan = [
  { url: 'https://i.ibb.co/6XnhSD0/hoaman1.jpg', alt: 'sodotongquan' },
  { url: 'https://i.ibb.co/Gdk0p44/hoaman5.jpg', alt: 'sodokhuvuc' },
  { url: 'https://i.ibb.co/Y3CJKmt/hoaman3.jpg', alt: 'phong1' },
  { url: 'https://i.ibb.co/mXwLYNC/hoaman4.jpg', alt: 'phong2' },
  { url: 'https://i.ibb.co/Gdk0p44/hoaman5.jpg', alt: 'phong3' },
]

const bungalowVuonLe = [
  { url: 'https://i.ibb.co/BKZcQ6m/vuonle1.jpg', alt: 'sodotongquan' },
  { url: 'https://i.ibb.co/PNz9g5z/vuonle2.jpg', alt: 'sodokhuvuc' },
  { url: 'https://i.ibb.co/pdk6mQm/vuonle3.jpg', alt: 'phong1' },
  { url: 'https://i.ibb.co/Gdk0p44/hoaman5.jpg', alt: 'phong2' },
  { url: 'https://i.ibb.co/K9KfLsV/vuonle5.jpg', alt: 'phong3' },
]

const khuyenmai = [
  {
    url: 'https://i.ibb.co/ZgH7QW4/khuyenmaispa.png',
    title: 'Gội đầu dưỡng sinh & giảm 20% dịch vụ spa',
    alt: 'khuyenmai1',
    date: '01-08-2023 - 02-09-2023',
    link: '/spa/goidauduongsinh'
  }, {
    url: 'https://i.ibb.co/hBVnS0G/amthuc.jpg',
    title: 'Giảm 50% Combo ẩm thực Tây bắc khi đặt khu phòng Family - Vườn Đào',
    alt: 'khuyenmai2',
    link: '/promotion/amthuc',
    date: '01-07-2023 - 02-10-2023'
  }, {
    url: 'https://i.ibb.co/5ktytD5/tour.jpg',
    title: 'Miễn phí phương tiện khi đặt tour khám phá',
    alt: 'khuyenmai3',
    date: '01-07-2023 - 02-09-2023',
    link: '/tours'
  }, {
    url: 'https://cucphuongresort.vn/wp-content/themes/cnv_theme/images/bn_top_blogdetail.jpg',
    title: 'Deal hè siêu hot – chốt ngay kẻo lỡ',
    alt: 'khuyenmai3',
    date: '01-06-2023 - 02-10-2023',
    link: '/promotion/uudaihe'
  }
]
export {
  headerMedia,
  imageList,
  galeryPhoto,
  introductionImg,
  // AccommdationPage
  roomLocationImage,
  bungalowHoaDao,
  bungalowHoaMan,
  bungalowVuonLe,
  // LoginPage
  loginFormBackGround,

  // room list
  roomList,
  image,

  // promotion
  khuyenmai
}
