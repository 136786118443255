import React from 'react'
import { Controller, Control, useFormContext } from 'react-hook-form'

import FormControlLabel from '@mui/material/FormControlLabel'
import { Checkbox, FormLabel } from '@mui/material'

const BaseCheckBox = (props: any) => {
  const {
    name,
    defaultValue = false,
    rules,
    label,
    title,
    required,
    ...other
  } = props
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <>
          <FormLabel component='legend'>{title}</FormLabel>
          <FormControlLabel
            label={
              label &&
              (required ? (
                <>
                  {label}
                  <span className='text-red ml-3'>* </span>
                </>
              ) : (
                <>{label}</>
              ))
            }
            control={<Checkbox {...field} checked={field.value || false} />}
            {...other}
          />
        </>
      )}
    />
  )
}

export default BaseCheckBox
