import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'react-bootstrap'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import './assets/style/App.scss'
import { PagePath } from './data/constants'
import Accommodation from './features/accommodations/components/Accommodation'
import PromotionDetail from './features/promotion/components/PromotionDetail'
import Footer from './layouts/Footer'
import Header from './layouts/Header'
import Accommodations from './pages/AccommodationsPage'
import Booking from './pages/Booking'
import ContactUs from './pages/ContactUs'
import Feedback from './pages/Feedback'
import Home from './pages/Home'
import LoginPage from './pages/LoginPage'
import MainPostScript from './pages/MainPostScript'
import Payment from './pages/Payment'
import { Services } from './pages/Services'
import SpecialOffers from './pages/SpecialOffer'
import StoriesPage from './pages/StoriesPage'
import TourDetail from './pages/TourDetail'
import Tours from './pages/Tours'
import UserDetail from './pages/UserDetail'
import useAppStore from './store/appStore'
import ActingService from './features/customerServices/ActingService'
import BookingHistory from './features/customerServices/BookingHistory'

export type Tag = {
  id: string
  label: string
}

function App() {
  const location = useLocation()
  const dataUser =
    localStorage.getItem('userInfo') &&
    JSON.parse(localStorage.getItem('userInfo') || '')
  const setCurrentPage = useAppStore((state) => state.setCurrentPage)
  setCurrentPage(location.pathname)
  return (
    <div id='app'>
      <Header />
      <Container>
        <Routes>
          <Route path={PagePath.Home} element={<Home />} />
          <Route path={PagePath.ContactUs} element={<ContactUs />} />
          <Route path='/specialOffers' element={<SpecialOffers />} />
          <Route path='/tours' element={<Tours />} />
          <Route path='/services' element={<Services />} />
          <Route path='/tour/:id' element={<TourDetail />} />
          <Route path='/ourStories' element={<StoriesPage />} />
          <Route path={PagePath.Accommodations} element={<Accommodations />} />
          <Route path={PagePath.Accommodation} element={<Accommodation />} />
          <Route path={PagePath.MainPostScript} element={<MainPostScript />} />
          <Route path={PagePath.Booking} element={<Booking />} />
          <Route path={PagePath.Payment} element={<Payment />} />
          <Route path={PagePath.Login} element={<LoginPage />} />
          <Route path={PagePath.ActingService} element={<ActingService />} />
          <Route path={PagePath.BookingHistory} element={<BookingHistory />} />
          <Route path='/booking/:id' element={<Booking />} />
          <Route path='/*' element={<Navigate to='/' />} />
          {dataUser?.token && (
            <Route path={PagePath.UserDetail} element={<UserDetail />} />
          )}
          <Route path={PagePath.Feedback} element={<Feedback />} />
          <Route path={PagePath.Promotion} element={<PromotionDetail />} />
        </Routes>
      </Container>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default App
