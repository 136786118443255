import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface BookingInfoStore {
  startDate?: string
  endDate?: string
  numOfRoom?: number
  occupancyAdult?: number
  occupancyChild?: number
  setBookingInfo: ( booking: BookingInfoStore | undefined ) => void
}

const useBookingInfoStore = create(
  devtools<BookingInfoStore>(
    set => ( {
      setBookingInfo: ( booking: BookingInfoStore | undefined ) => set( {
        startDate: booking ? booking.startDate : '',
        endDate: booking ? booking.endDate : '',
        numOfRoom: booking ? booking.numOfRoom : 1,
        occupancyAdult: booking ? booking.occupancyAdult : 1,
        occupancyChild: booking ? booking.occupancyChild : 0,
      } )
    } ), { enabled: true }
  ) )

export default useBookingInfoStore;