import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from '../../../assets/style/Booking.module.scss'
import { getYYYYMMDD } from '../../../services/dateUtil'
import { BaseButton } from '../../../components/form/BaseButton'
import { currencyFormat } from '../../../services/stringUtil'
import i18n from '../../../services/i18n'

interface BookingReviewProps {
  startDate: string
  endDate: string
  bookingNight: number
  selectRoomList: any
  occupancyAdult: number
  occupancyChild: number
  totalPrice: number
  isPayment?: boolean
  onContinue?: () => void
}

const BookingReview: FC<BookingReviewProps> = (props) => {
  const {
    startDate,
    endDate,
    bookingNight,
    selectRoomList,
    occupancyAdult,
    occupancyChild,
    totalPrice,
    isPayment = false,
    onContinue,
  } = props
  console.log('locale: ', i18n)
  return (
    <div>
      <div className={styled.sideBooking}>
        <div className={styled.sticky}>
          <div className={styled.title}>
            <h4>Chuyến đi</h4>
          </div>
          <div className={styled.section}>
            <h4>Địa điểm</h4>
            <p>Le Bruder Hồng Thái</p>
            {bookingNight > 0 ? (
              <>
                <div>
                  Thời gian
                  <br />
                  {getYYYYMMDD(startDate)} - {getYYYYMMDD(endDate)}
                </div>
                <div>({bookingNight} đêm)</div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className={styled.section}>
            <Row>
              <Col>
                <h4>Loại phòng</h4>
                <div>
                  {selectRoomList?.map((selectedRoom: any, index: any) => {
                    return (
                      <div>
                        <span>Phòng {index + 1}:</span>{' '}
                        <span>
                          Thành tiền {currencyFormat(selectedRoom.price, 'vi')}{' '}
                          vnđ
                        </span>{' '}
                      </div>
                    )
                  })}
                </div>
                <div>
                  {occupancyChild <= 0
                    ? `${occupancyAdult} người lớn`
                    : `${occupancyAdult} người lớn, ${occupancyChild} trẻ em`}
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col>
                <h4>Tổng cộng</h4>
                <p>(Bao gồm thuế phí)</p>
              </Col>
              <Col>
                <div className={styled.rightCmpnt}>
                  <p>{currencyFormat(totalPrice, 'vi')} vnđ</p>
                </div>
              </Col>
            </Row>
          </div>
          <BaseButton type='submit' onClick={onContinue}>
            {isPayment ? 'Xác nhận đặt phòng' : 'Tiếp tục'}
          </BaseButton>
        </div>
      </div>
    </div>
  )
}

export default BookingReview
