import { useTranslation } from "react-i18next";
import PromotionCard from "./PromotionCard";
import styled from "../styles/Section.module.scss";
import OverallMultiItemCarousel from "../../../components/ui/OverallMultiItemCarousel";
import { overViewPromotionsCarouselResponsive } from "../../../data/constants";
import { khuyenmai } from "../../../data/media";
const Promotions = () => {
  const { t } = useTranslation();
  let promotionCards = [];


  for (let i = 0; i < khuyenmai.length; i++) {
    promotionCards.push(
      <div className={styled.card}>
        <PromotionCard
          key={i}
          imgUrl={khuyenmai[i].url}
          title={khuyenmai[i].title}
          link={khuyenmai[i].link}
          expDt="01-07-2023 - 02-09-2023"
        />
      </div>
    );
  }

  return (
    <div className={styled.section}>
      <div className={styled.title}>
        <p>{t("content.promotion.specialOffers")}</p>
      </div>
      <div>
        <OverallMultiItemCarousel
          responsive={overViewPromotionsCarouselResponsive}
          itemList={promotionCards}
        />
      </div>
    </div>
  );
};

export default Promotions;
