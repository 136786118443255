import React from 'react'

export const CustomerScore = () => {
  const score = 2500
  let image: React.JSX.Element
  const dataUser =
    localStorage.getItem('userInfo') &&
    JSON.parse(localStorage.getItem('userInfo') || '')

  const getImage = (score: number): React.JSX.Element => {
    if (score > 16000 && score < 20000) {
      image = (
        <img
          src={require('../../../assets/images/step-progress-bar.png')}
          alt=''
        />
      )
    } else if (score > 14000 && score < 16000) {
      image = (
        <img
          src={require('../../../assets/images/step-progress-bar.png')}
          alt=''
        />
      )
    }
    return image
  }
  return (
    <div className='detail-content'>
      <div className='user-point'>
        <h5>Điểm thưởng của tôi</h5>
        <div>
          <div>Điểm khả dụng</div>
          <p>{dataUser?.membershipPoint}</p>
          <p>trị giá</p>
          <p>+0 Điểm đang chờ</p>
          <div>
            <h5>Tiến trình tích điểm</h5>
            <div className='image'>
              <img
                src={require('../../../assets/images/memberStepProgress/16000.jpg')}
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
