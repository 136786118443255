import styled from "../assets/style/StoriesPage.module.scss";
import { getLocale } from "../services/lang";
const StoriesPage = () => {
  const curLang = getLocale();
  console.log("curLang: ", curLang);
  return (
    <div className={styled.storiesPage}>
      <div className={styled.section}>
        <div>
          <h3>TỔNG QUAN VỀ LE BRUDER HỒNG THÁI</h3>
        </div>
        <div className={styled.introLebruder}>
          <article>
            <p>
              Hồng Thái, một xã vùng cao phía Đông Bắc của huyện Na Hang. Nằm ở
              độ cao 1200m so với mực nước biển, khí hậu quanh năm mắt mẻ khiến
              du khách đến Hồng Thái cảm nhận như một Sapa thứ 2 ở khu vực phía
              Bắc.
            </p>
            <p>
              Về du lịch, Hồng Thái như một viên ngọc sáng rực rỡ giữa đại ngàn
              Tây Bắc, nhắc đến Hồng Thái người ta nhớ đến ngay miền đất của 999
              ngọn núi, hồ nước xanh thẳm giữa những bản làng bình yên và thơ
              mộng, những vườn lê xinh tự chốn bồng lai xen lẫn những thử ruộng
              bậc thang ngút ngàn giữa trời mây.
            </p>
            <p>
              Sức mạnh hấp dẫn nhất ở Hồng Thái là vào mùa xuân, hoa lê, hoa mận
              rực rỡ màu trắng tinh, những vườn cải những vườn cải vàng bung nở
              bạt ngàn, tô điểm cho không gian núi rừng vẻ mơ mộng khó cưỡng,
              mùa hè Hồng Thái được tô điểm bởi những thửa ruộng bậc thang xanh
              mướt vắt ngang lưng đồi. Đến Hồng Thái mùa thu bạn sẽ phải chuếnh
              choáng bởi vẻ đẹp của những thửa ruộng bậc thang chín vàng óng,
              tầng tầng, lớp lớp sóng sánh và toả mùi hương lúa mới hay những
              vườn lê chín mọng ngọt say mê. Mùa đông Hồng Thái lại trở nên thật
              huyền ảo trong màn sương giăng và những tầng mây sà xuống bên vạt
              rừng, trên từng nóc nhà xinh.
            </p>
            <p>
              Đến Hồng Thái, bạn có thể thức dậy giữa bạt ngàn hoa lê, hoa mận,
              hay nằm trên những áng mây trôi lững lờ triền núi. Đây chính là
              khởi nguồn ý tưởng để chúng tôi xây dựng Le Bruder Hồng Thái.
            </p>
              <img
                src="https://baotuyenquang.com.vn/media/images/2018/06/img_20180629100114.jpg"
                alt="poolStory"
              />
              <p>
                Le Bruder Hồng Thái cách thị trấn Đà Vị khoảng 10 km, cách UBND
                xã Hồng Thái khoảng 3 km, một địa điểm tuyệt vời để bạn có những
                giây phút hòa mình với thiên nhiên, ngắm nhìn núi non hùng vĩ.
                Le Bruder Hồng Thái hoạt động dựa trên nguyên tắc phát triển bền
                vững như sử dụng nguồn lao động cũng như các sản phẩm và hàng
                hóa địa phương nhằm đem lại lợi ích kinh tế cho cộng đồng. Hơn
                thế nữa, chúng tôi cũng trang bị hệ thống xử lý nước thải để
                tránh gây ô nhiễm môi trường và bảo vệ thiên nhiên.
              </p>
          </article>
        </div>
      </div>
      <div className={styled.section}>
        <div>
          <h3>CÂU CHUYỆN HỒNG THÁI</h3>
        </div>
        <div className={styled.intro}>
          <h5>Bài 1: “Họa tiết trên trang phục người Dao Tiền”</h5>
          <article>
            <p>
              Trang phục truyền thống của người Dao Tiền lấy 2 màu sắc chủ đạo
              là tràm và đen để trang trí, Đây là hai màu sắc tinh tế, nhã nhặn
              và hài hòa, khi kết hợp với hoa văn trên vải, tạo nên một bộ trang
              phục độc đáo và khác biệt.
            </p>
            <p>
              Trên áo người Dao Tiền nhất thiết phải thêu các hoa văn hình trám,
              hình con chó, hình nhện và hoa (thêu ở 2 bên phía sau) cũng là đặc
              điểm riêng của trang phục. Các hình thêu chó, nhện là cả câu
              chuyện dài của dân tộc Dao Tiền.
            </p>
            <p>
              Truyền thuyết xưa kể lại rằng:
              <i>
                "Xưa kia có ông vua của người Dao không có con trai mà chỉ sinh
                được 12 người con gái. Một ngày kia giặc sang xâm lược, vua liền
                truyền rằng ai đánh thắng giặc vua sẽ gả cả cho 12 cô con gái,
                và vua cầu khấn trời để xin. Một buổi chiều xuất hiện 1 con Long
                khuyển ngũ sắc, là con chó mình rồng, 5 màu có 12 chiếc đuôi,
                chạy đến xin vua cho đánh giặc. Khi giặc đến thấy con chó lạ mắt
                liền bắt về nuôi, nó ngoan ngoãn theo về, đợi khi giặc ngủ say
                liền cắn cổ cho bọn giặc chết hết. Con chó trở về, theo đúng lời
                hứa, vua liền gả các con gái cho con chó và con chó được vua
                truyền ngôi báu. Để nhớ ơn con chó đã có công dẹp giặc cứu dân
                tộc nên đồng bào đã thêu hình con chó và 4 chiếc chân chó ở sau
                áo."
              </i>
            </p>
            <p>
              Ngoài ra, đồng bào Dao Tiền còn có một thuyết khác về hình con
              nhện:
              <i>
                "Xưa kia khi người Dao mới biết mặc quần áo thì cả đồng tộc bị
                đại dịch, do ăn phải phấn của con bướm trắng, bị chết rất nhiều,
                sau đó có người lấy con nhện đem nướng cháy hòa nước uống và
                khỏi bệnh, từ đó để nhớ ơn con nhện đã có công cứu tộc người nên
                họ thêu hình con nhện lên áo."
              </i>
            </p>
            <p>
              Người Dao Tiền còn có truyền thuyết khác về con chó:
              <i>
                "Theo truyền thuyết, Bàn Vương là con chó thần, vị tổ của người
                Dao nên phải nhớ đến, phải có hình ảnh trên vạt áo của từng
                người."
              </i>
            </p>
            <p>
              Và từ đó, khi dệt vải, may áo, phụ nữ Dao Tiền cũng thêu một bức
              tranh mang hình nhiều con chó hoặc con nhện ở vạt áo phía sau. Có
              trang phục thuê tới 16 con chó, mỗi con một dáng vẻ sinh động.
              Trên một vùng vải trang trí nhỏ, con thì châu đầu vào nhau như tâm
              tình thủ thỉ, con thì ngoảnh mặt ra bốn phía quan sát. Bức tranh
              này thể hiện ý thức cội nguồn và là một minh chứng cho tài năng
              sáng tạo của phụ nữ Dao Tiền về tài thêu thùa, trang trí. Đó cũng
              là tiêu chuẩn để các chàng trai Dao Tiền đánh giá sự khéo léo của
              bạn tình mình và là tiêu chuẩn tài đức để các chàng trai chọn vợ.
            </p>
            <p>
              Người Dao Tiền luôn mặc trang phục truyền thống của mình trong các
              lễ hội, đi chợ…chị em phụ nữ Dao Tiền rất duyên dáng, nhã nhặn
              trong trang phục truyền thống như những cánh bướm của núi rừng hồn
              nhiên và trong sáng.
            </p>
            <img
              src="https://i.ibb.co/3N78BtR/auphucnguoidao.png"
              alt="auphucnguoidao"
            />
          </article>
        </div>
      </div>
    </div>
  );
};

export default StoriesPage;
