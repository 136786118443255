import { getI18n } from "react-i18next";
import { Languages } from "../data/constants";

const getLocale = () => {
    const lang = getI18n().language;
    let curLang = Languages.vi;
    switch (lang) {
        case "en":
            curLang = Languages.en
            break;
    }
    return curLang;
}

export {getLocale}